
import { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import useBrowserInfo from '../../Layout/BrowserInfo';
import useGui from '../../stores/guistore';
import useUserStore, { LoginState } from "../../stores/userstore";

import { ReactComponent as MenuButtonSvg } from "./Images/menu-button.svg";
import { ReactComponent as RightArrowButtonSvg } from "./Images/right-arrow-button.svg";

// ****************************************************************************

interface MenuItem {
    text: string;
    action: (navigate: NavigateFunction) => void;
}

const gMainMenu: MenuItem[] = [
    {
        text: "Karte",
        action: (navigate) => navigate('/'),
    },
    {
        text: "Profil",
        action: (navigate) => navigate('/profile'),
    },
    {
        text: "Abmelden",
        action: (navigate) => { navigate('/'); useUserStore.getState().logout(); },
    },
];

// ****************************************************************************

const MenuItemList = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin: 0 0 0 1.4em;
        a {
            color: #444;
            cursor: pointer;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
        button {
            padding: 0!important;
            background: none!important;
            border: none;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    }
`;

const UserName = styled.div`
    margin: 0;
    padding: 0;
`;

interface MenuItemProps {
    menu: MenuItem[];
    onMenuClose?: () => void;
}

function MenuItems(props: MenuItemProps) {
    const { menu, onMenuClose } = props;
    const navigate = useNavigate();
    return (
        <MenuItemList onMouseLeave={() => onMenuClose && onMenuClose()}>
            { menu.map((item) => {
                return <li key={item.text}><button
                    onClick={() => { onMenuClose && onMenuClose(); item.action(navigate) }}
                >{item.text}</button></li>
            })}
        </MenuItemList>
    );
}

// ****************************************************************************

export const DesktopMenuNav = styled.nav`
    flex-grow: 1;
    text-align: right;
    color: #444;
`;

export function DesktopMenu() {
    const loginState = useUserStore((store) => store.loginState);
    const userData = useUserStore((store) => store.data);
    if ((userData == null) || (loginState !== LoginState.LOGGED_IN))
        return null;
    return (
        <DesktopMenuNav>
            <UserName>Angemeldet: {(userData.fullName !== '')? userData.fullName: userData.userName}</UserName>
            <MenuItems menu={gMainMenu}/>
        </DesktopMenuNav>
    );
}

// ****************************************************************************

const gSpacing = "1em";

const MobileMenuContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${gSpacing};
    background-color: #2869af;
    svg {
        display: block;
        fill: #fff;
    }
`;
// FIXME: background-color: ${p => p.theme.cols.high1};
// fill: ${p => p.theme.cols.textHigh1};

const MobileMenuButton = styled.div`
    margin: .3em ${gSpacing};
`;

const MobileSidebarButton = styled.div<{$isOpen: boolean}>`
    margin: ${gSpacing};
    height: 18px; width: 12px;
    transform: rotate(${p => p.$isOpen? "180deg": "0"});
    transition: .3s ease-in-out;
`;

const MobileMenuNav = styled.nav`
    ul {
        z-index: 4500;
        display: block !important;
        position: absolute;
        right: 20px;
        margin: 0;
        padding: 0;
        background-color: #eee;
        border: 3px solid #288732;
        li {
            display: list-item;
            margin: .6em 1em;
            text-align: left;
            cursor: pointer;
        }
    }
`;

export function MobileMenu() {
    const [ menuActive, setMenuActive ] = useState(false);

    const browser = useBrowserInfo();
    const loginState = useUserStore((store) => store.loginState);

    const isSidebarVisible = useGui((state) => state.isSidebarVisible);

    const onMenuButtonClicked = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        setMenuActive(!menuActive);
    };

    const onSidebarButtonClicked = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        useGui.getState().setSidebarVisible(!isSidebarVisible);
    };

    const onWindowClicked = () => {
        setMenuActive(false);
    };

    useEffect(() => {
        window.addEventListener('click', onWindowClicked);
        return () => {
            window.removeEventListener('click', onWindowClicked);
        };
    });

    // FIXME: should not use login state here, but a "has mobile menu" state
    if (!browser.isMobile || (loginState !== LoginState.LOGGED_IN))
        return null;

    return (
        <MobileMenuContainer>
            <MobileSidebarButton $isOpen={isSidebarVisible} onClick={onSidebarButtonClicked}><RightArrowButtonSvg/></MobileSidebarButton>
            <MobileMenuButton onClick={onMenuButtonClicked}><MenuButtonSvg/>
                { menuActive &&
                    <MobileMenuNav>
                        <MenuItems menu={gMainMenu} onMenuClose={() => setMenuActive(false)} />
                    </MobileMenuNav>
                }
            </MobileMenuButton>
        </MobileMenuContainer>
    );
}
