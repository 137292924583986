
import { useEffect, useRef } from "react";

import { LatLngExpression } from "leaflet";
import { MapContainer, TileLayer } from "react-leaflet";

import { MapServiceList } from "../../types/mapservice";

import useMapStore from "../../stores/mapstore";
import useRouteStore from "../../db/routedb";

import "./MapDisplay.css";


type Props = React.PropsWithChildren<{}>;

export default function MapDisplay(props: Props)
{
    const { children } = props;

    const mapService = useMapStore((state) => state.mapService);
    const mapLocation = useMapStore((state) => state.mapLocation);
    const mapRef = useRef<L.Map|null>(null);

    useEffect(() => {
        if (!mapRef.current) return;
        console.log("[MAP] created")
        const route = useRouteStore.getState().activeObject;
        if (!route || route.waypoints.length < 2) return;
        mapRef.current.fitBounds(route.getRouteBBox());
    }, [mapRef]);

    useEffect(() => {
        if (!mapLocation || !mapRef.current) return;
        mapRef.current.setView(mapLocation, 14);
        useMapStore.getState().setMapLocation(null);
    }, [mapLocation, mapRef])

    return (
        <MapContainer ref={mapRef} center={[49, 10] as LatLngExpression} zoom={7} scrollWheelZoom={true}>
            <TileLayer
                key={MapServiceList[mapService].name}
                url={MapServiceList[mapService].url}
                attribution={MapServiceList[mapService].attribution}
            />
            {children}
        </MapContainer>
    );
}
