
import { UserLoginData } from "../../types/user";
import useUserStore, { LoginState } from "../../stores/userstore";

import { useForm } from 'react-hook-form';
import { SubmitButton, TextInput } from '../GuiElements/forms';
import { getLoginErrorMessage } from "./UserLoginError";


const onPasswordReset = (loginData: UserLoginData) => {
    useUserStore.getState().resetPassword(loginData);
};


export default function UserPasswordResetForm() {
    const loginState = useUserStore((store) => store.loginState);
    const loginError = useUserStore((store) => store.loginError);

    const { register, handleSubmit, formState: { errors }, } = useForm<UserLoginData>({
        mode: "onChange",
        shouldUseNativeValidation: true,
    });

    if (loginState !== LoginState.PASSWORD_RESET_SHOW) return null;

    return (
        <form onSubmit={handleSubmit(onPasswordReset)}>
            <h2>Passwort zurücksetzen</h2>
            <p>
                Bitte gib unten Deinen Benutzername oder Deine hinterlegte E-Mail-Adresse ein.
                Wir senden Dir an diese Adresse eine E-Mail mit einem Link, über den Du ein neues Passwort setzen kannst.
            </p>
            { loginError && getLoginErrorMessage(loginError) }
            <TextInput error={errors.userName?.message}
                label="Benutzername oder E-Mail"
                {...register("userName", { required: "Bitte Benutzernamen oder E-Mail-Adresse angeben." })}
            />
            <SubmitButton/>
        </form>
    );
}
