
import React from "react";
import styled from "styled-components";

import useModalDialog from "../../stores/modalstore";
import useGui from "../../stores/guistore";
import { DialogButton } from "../../types/modal";


const Background = styled.div`
    position: fixed;
    top: 0; right: 0; bottom: 0; left: 0;
    z-index: 5000;
    background-color: #000;  // fallback
    background-color: rgba(0, 0, 0, 0.5);
`;

const Dialog = styled.div`
    min-width: 300px;
    max-width: 90%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: fixed;
    top: 50%; left: 50%;
    z-index: 5005;
    background-color: ${p => p.theme.cols.bg};
    color: #444;
    border-radius: 1em;
    box-shadow: 0 0 5px 3px #444;
    padding: 1em;
    transform: translate(-50%, -50%);
`;

const DialogTitle = styled.div`
    font-size: 1.6em;
    color: ${p => p.theme.cols.high1};
`;

export const ButtonBox = styled.div`
    margin-top: .8em;
    display: flex;
    justify-content: right;
    gap: .8em;
`;

export const ButtonBoxFill = styled.div`
    margin-top: .8em;
    display: flex;
    justify-content: space-between;
    gap: .8em;
    button { flex-grow: 1; }
`;

const ButtonStyle = styled.button<{$bgCol: string, $textCol: string}>`
    padding: .3em .5em;
    border: 1px solid ${p => p.theme.cols.bg};
    background-color: ${p => p.$bgCol};
    color: ${p => p.$textCol};
    cursor: pointer;

    &:hover {
        border-color: ${p => p.$bgCol};
    }
`;

export function Button(props: React.PropsWithChildren<{onClick?: React.MouseEventHandler, color?: number, type?: "submit"}>) {
    const color = props.color || 1;
    const theme = useGui((state) => state.theme);
    let bgCol, textCol;
    if (color === 2) {
        bgCol = theme.cols.high2;
        textCol = theme.cols.textHigh2;
    } else {
        bgCol = theme.cols.high1;
        textCol = theme.cols.textHigh1;
    }
    return <ButtonStyle type={props.type} onClick={props.onClick} $bgCol={bgCol} $textCol={textCol}>{props.children}</ButtonStyle>;
}

// ****************************************************************************

const onClickButton = (ev: React.MouseEvent, b: DialogButton) => {
    ev.preventDefault();
    ev.stopPropagation();
    b.onClick?.();
    useModalDialog.getState().close();
};

const onClickDialog = (ev: React.MouseEvent) => {
    // do not prevent defaults, that breaks submit buttons
    ev.stopPropagation();
    // ignore clicks on dialog background
};

const onClickBackground = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    useModalDialog.getState().close();
};

// ****************************************************************************

type ModalDialogProps = React.PropsWithChildren<{
    title?: string;
    buttons?: DialogButton[];
    closeOnBackgroundClick?: boolean;
}>;

export const ModalDialog = (props: ModalDialogProps) => {

    return (
        <Background onClick={onClickBackground}>
            <Dialog onClick={onClickDialog}>
                { props.title && <DialogTitle>{props.title}</DialogTitle> }
                {props.children}
                { (props.buttons && (props.buttons.length > 0)) &&
                    <ButtonBox>{props.buttons.map((b) =>
                        <Button color={b.color} key={b.text} onClick={(ev) => onClickButton(ev, b)}>{b.text}</Button>)}
                    </ButtonBox>
                }
            </Dialog>
        </Background>
    );
}

// ****************************************************************************

export function MainModalDialog()
{
    const modal = useModalDialog((state) => state.modal);
    if (!modal) return null;

    return (
        <ModalDialog buttons={modal.buttons}>
            {modal.content}
        </ModalDialog>
    );
}

// ****************************************************************************

export const CloseButton : DialogButton = {
    text: "Schließen",
}

export const CancelButton : DialogButton = {
    text: "Abbrechen",
}
