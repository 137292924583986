
import { Ebike } from "../types/ebike";

import DbCollection from "../db/collection";
import { createStore } from "../stores/zustand";
import { addLoginStateObserver, LoginState } from "../stores/userstore";
import useMotorStore from "./MotorCollection";

// ****************************************************************************

class EbikeCollection extends DbCollection<Ebike, Ebike>
{
    constructor() {
        super("ebike");
    }

    fromDto(dto: Ebike): Ebike {
        return dto;
    }

    toDto(object: Ebike): Ebike {
        return object;
    }

    hookPostSelect() {
        const activeEbike = useEbikeStore.getState().activeObject;
        if (activeEbike) {
            // FIXME: handle error "motor does not exist"
            useMotorStore.getState().setById(activeEbike.motor);
        } else {
            useMotorStore.getState().clear();
        }
    }

    setIsEditing(isEditing: boolean) {
        useEbikeStore.getState().setIsEditing(isEditing);
    }
}

// ****************************************************************************

export const EbikeDb = new EbikeCollection();

const useEbikeStore = createStore(EbikeDb.storeConfig);

addLoginStateObserver((newState, oldState) => {
    if (newState === LoginState.LOGGED_IN) {
        useEbikeStore.getState().updateList();
    } else if (newState === LoginState.LOGGED_OUT) {
        useEbikeStore.getState().clear();
    }
});

export default useEbikeStore;
