
import React from "react";
import styled from "styled-components";

import useBrowserInfo from "./BrowserInfo";


const NarrowContentWrapper = styled.div<{$windowWidth: number}>`
    flex-grow: 1;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    section.content {
        max-width: 960px;
        margin: 0 ${p => (p.$windowWidth < 1000)? '20px': 'auto'};
    }
`;


export default function NarrowContent(props: React.PropsWithChildren<{}>) {
    const { children } = props;
    const browser = useBrowserInfo();
    return (
        <NarrowContentWrapper $windowWidth={browser.width}>
            <section className="content">
                {children}
            </section>
        </NarrowContentWrapper>
    );
}
