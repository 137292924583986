
import styled from "styled-components";

import useMotorStore from "../../Database/MotorCollection";
import useRdgStore, { getPredictionResults, getSupportMode } from "../../stores/Rdg";
import { getSlopePlotColor } from "../Results/PlotElevationProfile";
import { PlotColor, ResultPlotColor } from "../Results/ResultPlotConfig";

// ****************************************************************************

// TODO: override margin in .leaflet-popup-content

const PopupTable = styled.table`
    font-size: 90%;
    td {
        text-align: right;
        margin-left: .8em
    }
`;

// ****************************************************************************

interface RoutePopupData {
    distance: number;
    ridetime?: number;
    altitude: number;
    slope: number;
    slopeStyle: React.CSSProperties;
    supportMode?: string;
    supportStyle?: React.CSSProperties;
};

export function RoutePopup(props: { data: RoutePopupData | null })
{
    const { data } = props;
    if (data == null) return null;
    return (
        <PopupTable>
            <tbody>
                <tr><th>Stecke</th><td>{data.distance} km</td></tr>
                { data.ridetime && <tr><th>Fahrtzeit</th><td>{data.ridetime} min</td></tr> }
                <tr><th>Höhe</th><td>{data.altitude} m</td></tr>
                <tr><th>Steigung</th><td style={data.slopeStyle}>{data.slope} %</td></tr>
                { data.supportMode && <tr><th>Unterstützung</th><td style={data.supportStyle}>{data.supportMode}</td></tr> }
            </tbody>
        </PopupTable>
    );
}

export function getRoutePopupData(routeIndex: number, plotIndex: number)
{
    const rdgStore = useRdgStore.getState();
    const activeMotor = useMotorStore.getState().activeObject;
    const routePlotData = useRdgStore.getState().routePlotData;
    const results = getPredictionResults(rdgStore);

    if (!routePlotData) return null;

    const slope = routePlotData.slope[plotIndex];

    const supportModeIndex = getSupportMode(rdgStore, routeIndex);
    const supportMode: string | undefined =
        (supportModeIndex != null)? activeMotor?.supportNames[supportModeIndex]: undefined;
    const supportStyle =
        (supportModeIndex != null)? { color: ResultPlotColor[supportModeIndex as PlotColor] }: undefined;

    return {
        distance: Math.round(routePlotData.distance[plotIndex]),
        ridetime: results?.rideTime[plotIndex],
        altitude: routePlotData.altitude[plotIndex],
        slope: slope,
        slopeStyle: { color: getSlopePlotColor(slope) },
        supportMode: supportMode,
        supportStyle: supportStyle,
    }
}
