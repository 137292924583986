
import styled from "styled-components";

import { Accordion, AccordionPanel } from "../GuiElements/accordion";
import EbikeControl from "./EbikeControl";
import PredictionSettings from "./PredictionSettings";
import ResultDisplay from "./ResultDisplay";
import RiderControl from "./RiderControl";
import RouteSettings from "./RouteSettings";

const MainSidebarContainer = styled.div`
    overflow-y: auto;
`;

export default function MainSidebar() {
    return (
        <MainSidebarContainer>
            <Accordion>
                <AccordionPanel heading="Route">
                    <RouteSettings/>
                </AccordionPanel>
                <AccordionPanel heading="Unterstützungsprofil">
                    <PredictionSettings/>
                </AccordionPanel>
                <AccordionPanel heading="Auswertung" initiallyOpen={true} bgColor="#288732">
                    <ResultDisplay/>
                </AccordionPanel>
                <RiderControl/>
                <EbikeControl/>
            </Accordion>
        </MainSidebarContainer>
    );
}
