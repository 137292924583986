
import { useEffect } from "react";

import styled from "styled-components";

import useBrowserInfo from "../../Layout/BrowserInfo";
import useGui from "../../stores/guistore";
import ComputeResults from "../../Compute/ComputeResults";
import MainSidebar from "./MainSidebar";
import MapEditor from "../Map/MapEditor";
import { MainModalDialog } from "../GuiElements/modaldialog";
import EbikeSettingsDialog from "./EbikeSettings";
import RiderSettingsDialog from "./RiderSettings";

import "./MainPage.css";

// ****************************************************************************

const OuterPageContainer = styled.div`
    position: relative;
    margin: 0;
    flex-grow: 1;
    overflow: hidden;
`;

const DesktopPageContainer = styled.div`
    width: 100%; height: 100%;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    overflow: hidden;

    > div {
        flex-grow: 1;
    }
`;

const DesktopSidebarContainer = styled.div`
    flex-grow: 0 !important;
    flex-shrink: 0;
    width: 300px; max-width: 100%;
    padding: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    gap: 1.4em;
`;

function DesktopMainPage() {
    return (
        <DesktopPageContainer>
            <DesktopSidebarContainer>
                <MainSidebar/>
            </DesktopSidebarContainer>
            <MapEditor/>
        </DesktopPageContainer>
    );
}

// ****************************************************************************

const MobilePageContainer = styled.div`
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    overflow: auto;
`;

const MobileSidebarWrapper = styled.div<{$isVisible: boolean}>`
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
    transform: translate(${p => p.$isVisible? '0': '-102%'}, 0);
    transition: .3s ease-in-out;
    z-index: 4000;
`;

const MobileSidebarContainer = styled.div`
    width: 300px; max-width: 100%;
    height: 100%;
    background-color: #eee;
    box-shadow: 0 0 5px;
    z-index: 4500;
`;

const MobileMapContainer = styled.div`
    position: absolute;
    top: 0; bottom: 0; left: 0; right: 0;
`;

const onCloseSidebar = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    useGui.getState().setSidebarVisible(false);
}

const onIgnoreBackgroundClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
}

function MobileMainPage()
{
    const isVisible = useGui((state) => state.isSidebarVisible);

    useEffect(() => {
        useGui.getState().setSidebarVisible(true);
    },
    []);

    return (
        <MobilePageContainer>
            <MobileSidebarWrapper $isVisible={isVisible} onClick={onCloseSidebar}>
                <MobileSidebarContainer onClick={onIgnoreBackgroundClick}>
                    <MainSidebar/>
                </MobileSidebarContainer>
            </MobileSidebarWrapper>
            <MobileMapContainer>
                <MapEditor/>
            </MobileMapContainer>
        </MobilePageContainer>
    );
}

// ****************************************************************************

export default function MainMapPage() {

    const browser = useBrowserInfo();

    return (
        <OuterPageContainer>
            <ComputeResults/>
            <EbikeSettingsDialog/>
            <MainModalDialog/>
            <RiderSettingsDialog/>
            { browser.isMobile? <MobileMainPage/>: <DesktopMainPage/>}
        </OuterPageContainer>
    );
}
