
import { createStore } from "../stores/zustand";
import { postRequest } from "../db/webapi";

import gWebSocket from "../db/websocket";
import { UserAccountDeleteDto, UserChangePasswordDto, UserData, UserLoginData, UserMessageDto, UserNewPasswordDto, UserRegistrationData } from "../types/user";
import { storeLoginToken, getLoginToken, storeSessionToken } from "./Tokens";
import { showAcceptResearchDialog } from "../Components/dialogs/user-dialogs";


export enum LoginState {
    LOGGED_OUT,
    LOGGED_IN,
    LOGGING_IN,
    LOGGING_OUT,
    AUTO_LOGIN,
    PASSWORD_RESET_SHOW,
    PASSWORD_RESET_REQUESTED,
    PASSWORD_RESET_SENT,
    PASSWORD_NEW_REQUESTED,
    ACCOUNT_DELETED,
}


interface ILoginStateObserver {
    (newState: LoginState, oldState: LoginState): void;
}

const gLoginStateObservers: ILoginStateObserver[] = [];

function notifyLoginStateObservers(newState: LoginState, oldState: LoginState) {
    gLoginStateObservers.forEach(observer => observer(newState, oldState));
}

export function addLoginStateObserver(observer: ILoginStateObserver) {
    gLoginStateObservers.push(observer);
}


type UserState = {
    data: UserData|null;
    loginState: LoginState;
    loginError: string|null;
    passwordMessage: string|null;
    deleteMessage: string|null;
    registrationError: string|null;
    userTokenType: string|null;
    register: (registrationData: UserRegistrationData) => void;
    login: (loginData: UserLoginData) => void;
    logout: () => void;
    setLoginState: (newState: LoginState) => void;
    setLoginError: (message: string|null) => void;
    setPasswordMessage: (message: string|null) => void;
    setDeleteMessage: (message: string|null) => void;
    setAcceptResearch: (acceptResearch: boolean) => void;
    resetPassword: (loginData: UserLoginData) => void;
    newPassword: (passwordData: UserNewPasswordDto) => void;
    changePassword: (passwordData: UserChangePasswordDto) => void;
    deleteAccount: (data: UserAccountDeleteDto) => void;
    validateToken: (token: string) => void;
    setUserTokenType: (type: string|null) => void;
    demoLogin: () => void;
    tryLoginWithToken: () => void;
    doTryLoginWithToken: (loginToken: string) => void;
    dbQuery: (method: string, postData: any) => void;
}


const useUserStore = createStore<UserState>((set, get) => ({
    data: null,
    loginState: LoginState.LOGGED_OUT,
    loginError: null,
    passwordMessage: null,
    deleteMessage: null,
    registrationError: null,
    userTokenType: null,
    observers: [],

    register: (registrationData) => {
        const state = get();
        state.setLoginState(LoginState.LOGGING_IN);
        state.dbQuery('register', registrationData);
    },

    login: (loginData) => {
        const state = get();
        state.setLoginState(LoginState.LOGGING_IN);
        state.dbQuery('login', loginData);
    },

    logout: () => {
        const state = get();
        state.setLoginState(LoginState.LOGGING_OUT);
        state.dbQuery('logout', '');
    },

    setAcceptResearch: (acceptResearch: boolean) => {
        installWebsocketCallbacks();
        gWebSocket.send('user_accept_research', acceptResearch);
    },

    resetPassword: (loginData) => {
        installWebsocketCallbacks();
        get().setLoginState(LoginState.PASSWORD_RESET_REQUESTED);
        gWebSocket.send('user_reset_password', loginData.userName);
    },

    newPassword: (passwordData) => {
        installWebsocketCallbacks();
        get().setLoginState(LoginState.PASSWORD_NEW_REQUESTED);
        gWebSocket.send('user_new_password', passwordData);
    },

    changePassword: (passwordData) => {
        installWebsocketCallbacks();
        gWebSocket.send('user_change_password', passwordData);
    },

    deleteAccount: (data) => {
        installWebsocketCallbacks();
        gWebSocket.send('user_delete_account', data);
    },

    validateToken: (token) => {
        installWebsocketCallbacks();
        gWebSocket.send('user_token_validate', token);
    },

    setUserTokenType: (type) => {
        set((state) => {
            state.userTokenType = type;
        });
    },

    demoLogin: () => {
        get().doTryLoginWithToken('OGwBG9ifAxOUGBfbbLpj');
    },

    tryLoginWithToken: () => {
        let loginToken = getLoginToken();
        if (loginToken)
           get().doTryLoginWithToken(loginToken);
    },

    doTryLoginWithToken: (loginToken: string) => {
        const state = get();
        state.setLoginState(LoginState.AUTO_LOGIN);
        state.dbQuery('login-token', { loginToken: loginToken });
    },

    setLoginState: (newState) => {
        console.log("[USER] LoginState: " + LoginState[newState]);
        const oldState = get().loginState;
        set((state) => {
            state.loginState = newState;
            state.passwordMessage = null;
            state.deleteMessage = null;
        });
        notifyLoginStateObservers(newState, oldState)
    },

    setLoginError: (message) => {
        set((state) => {
            state.loginError = message;
        });
    },

    setPasswordMessage: (message) => {
        set((state) => {
            state.passwordMessage = message;
        });
    },

    setDeleteMessage: (message) => {
        set((state) => {
            state.deleteMessage = message;
        });
    },


    dbQuery: (method, postData) => {
        postRequest<UserData>("/user/" + method, postData)
            .then((userData) => {
                storeLoginToken(userData.loginToken);
                storeSessionToken(userData.sessionToken);
                set((state) => {
                    state.data = userData;
                    state.loginError = null;
                    state.registrationError = null;
                });
                if (!userData.agreedResearch) {
                    showAcceptResearchDialog();
                }
                get().setLoginState(LoginState.LOGGED_IN);
            })
            .catch((error) => {
                console.log("[USER QUERY]", method, error);
                storeLoginToken('');
                storeSessionToken('');
                let errorCode = "error:unknown";
                if (error.status === 401) {
                    errorCode = "error:unauthorized";
                } else if (error.status === 409) {
                    if (error.body.error) {
                        errorCode = "error:" + error.body.error;
                    }
                } else if (error.status === 500) {
                    errorCode = "error:server";
                }
                let loginError: string|null = null, registrationError: string|null = null;
                if (method === 'logout') {
                    // nothing to do here
                } else if (method === 'register') {
                    registrationError = errorCode;
                } else {
                    loginError = errorCode;
                }
                set((state) => {
                    state.data = null;
                    state.loginError = loginError;
                    state.registrationError = registrationError;
                });
                get().setLoginState(LoginState.LOGGED_OUT);
            });
    },
}));

// ****************************************************************************

let gWsCallbacksInstalled = false;

function installWebsocketCallbacks()
{
    if (gWsCallbacksInstalled) return;
    gWsCallbacksInstalled = true;
    gWebSocket.addHandler("user_message_success", (data) => onUserMessageSuccess(data));
    gWebSocket.addHandler("user_message_error", (data) => onUserMessageError(data));
    gWebSocket.addHandler("user_password", (data) => onUserPassword(data));
    gWebSocket.addHandler("user_delete", (data) => onUserDelete(data));

    gWebSocket.addHandler("user_token_validate",
        (data) => useUserStore.getState().setUserTokenType(data));
}

const onUserMessageSuccess = (data: string) => {
    const dto = JSON.parse(data) as UserMessageDto;
    const message = dto.message;
    if (message === 'reset_password_email_sent') {
        const state = useUserStore.getState();
        state.setLoginState(LoginState.PASSWORD_RESET_SENT);
    }
};

const onUserMessageError = (data: string) => {
    const dto = JSON.parse(data) as UserMessageDto;
    const message = dto.message;
    const state = useUserStore.getState();
    state.setLoginError('error:' + message);
    state.setLoginState(LoginState.LOGGED_OUT);
};

const onUserPassword = (data: string) => {
    if (data === 'success')
        data = 'success:password_changed';
    useUserStore.getState().setPasswordMessage(data);
}

const onUserDelete = (data: string) => {
    const state = useUserStore.getState();
    if (data === 'success') {
        state.setDeleteMessage(null);
        state.setLoginState(LoginState.ACCOUNT_DELETED);
    } else {
        state.setDeleteMessage(data);
    }
};

// ****************************************************************************

useUserStore.getState().tryLoginWithToken();

export default useUserStore;
