
const apiEnvironment = "relative";

const host = window.location.host;

const apiServer = {
    relative: {
        api: "https://" + host + "/api/v1",
        ws: "wss://" + host + "/api/v1/ws",
    },
    local: {
        api: "http://localhost:5123/api/v1",
        ws: "ws://localhost:5123/api/v1/ws",
    },
    production: {
        api: "https://ebike-planner.ieem-ka.de/api/v1",
        ws: "wss://ebike-planner.ieem-ka.de/api/v1/ws",
    },
    develop: {
        api: "https://ebike-planner-dev.ieem-ka.de/api/v1",
        ws: "wss://ebike-planner-dev.ieem-ka.de/api/v1/ws",
    },
};

export const ApiEndpoint = apiServer[apiEnvironment].api;
export const WebSocketEndpoint = apiServer[apiEnvironment].ws;
