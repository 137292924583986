
import styled from "styled-components";


export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
    column-gap: 1em;
`;


const NormalCell = styled.div`
    white-space: nowrap;
`;

const BoldCell = styled.div`
    white-space: nowrap;
    font-weight: bold;
    color: #d72305;
`;


export const GridPair = (props: { left: string; right: string; bold?: boolean }) => {
    if (props.bold) {
        return (
            <>
                <BoldCell>{props.left}:</BoldCell>
                <BoldCell>{props.right}</BoldCell>
            </>
        );
    } else {
        return (
            <>
                <NormalCell>{props.left}:</NormalCell>
                <NormalCell>{props.right}</NormalCell>
            </>
        );
    }
};
