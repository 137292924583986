import { Coordinate } from "../../types/route";

export type ResultPlotData = {
    i: number;  // index
    c: Coordinate; // coordinate lat/lng
    d: number;  // distance (m)
    a: number;  // elevation (m)
    s: number;  // slope (%)
    t?: number;  // ride time (min)
}

// ****************************************************************************

export enum PlotColor {
    Gray = -1,
    Black = 0,
    Green,
    Blue,
    Deeppink,
    Red,
    Deepred,
    Orange,
    Yellow,
}

export const ResultPlotColor: Record<PlotColor, string> = {
    [PlotColor.Gray]: "#808080",
    [PlotColor.Black]: "#000000",
    [PlotColor.Green]: "#008000",
    [PlotColor.Blue]: "#0000FF",
    [PlotColor.Deeppink]: "#FF1493",
    [PlotColor.Red]: "#FF0000",
    [PlotColor.Deepred]: "#7C0D0E",
    [PlotColor.Orange]: "#FFA500",
    [PlotColor.Yellow]: "#FFFF00",
}

// ****************************************************************************

export enum ResultPlotType {
    ELEVATION_PROFILE,
}

export type ResultPlotConfig = {
    title: string;
    axes: string;
    rangeMin?: number;
    rangeMax?: number;
}

export const ResultPlots: Record<ResultPlotType, ResultPlotConfig> = {
    [ResultPlotType.ELEVATION_PROFILE]: {
        title: "Höhenprofil",
        axes: "da",
    },
};
