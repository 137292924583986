
import { ReactElement } from "react";
import { immerable } from "immer";

// ****************************************************************************

export class DialogButton
{
    text: string;
    color?: number;
    onClick?: () => void;

    constructor(text: string, onClick: () => void, color?: number) {
        this.text = text;
        this.onClick = onClick;
        this.color = color;
    }
}

// ****************************************************************************

export class Modal
{
    [immerable] = true;

    content: ReactElement;
    buttons: DialogButton[];

    constructor(content: ReactElement, buttons: DialogButton[]) {
        this.content = content;
        this.buttons = buttons;
    }
}
