
import styled from 'styled-components';
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

import useGui from '../../stores/guistore';
import useRouteStore from '../../db/routedb';
import { MapMarkerIcon } from "../Map/MapMarker";
import { Route, Waypoint } from '../../types/route';
import { ButtonDelete } from '../GuiElements/IconButtons';

// ****************************************************************************

const WaypointListStyle = styled.div`
    height: 300px;
    overflow: auto;
    border: solid 1px #888;
    border-radius: 4px;
    .list-item {
        touch-action: pan-y;
        margin: .3em 1.5em .3em .5em;
        display: flex;
        justify-content: space-between;
        .item-content {
            flex-grow: 1;
            .list-marker {
                display: inline-block;
                width: 60px;
                margin-right: .6em;
                text-align: center;
                .map-marker {
                    display: inline-block;
                }
            }
        }
    }
`;

// ****************************************************************************

const onWaypointClicked = (ev: React.MouseEvent, index: number) => {
    ev.preventDefault();
    ev.stopPropagation();
    useGui.getState().setSelectedWaypoint(index);
}

const onRemoveWaypoint = (index: number) => {
    Route.updateRoute(route => route.removeWaypoint(index));
};

function WaypointListItem(props: { wp: Waypoint, index: number, isActive: boolean, isLast: boolean }) {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
    } = useSortable({id: props.wp.id});

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div className="list-item" ref={setNodeRef} style={style} {...attributes}>
            <div className='item-content' onClick={ev => onWaypointClicked(ev, props.index)} ref={setActivatorNodeRef} {...listeners}>
                <div className='list-marker'>
                    <MapMarkerIcon index={props.index} isActive={props.isActive} isLast={props.isLast} />
                </div>
                { `${props.wp.lat.toFixed(4)}, ${props.wp.lng.toFixed(4)}` }
            </div>
            <ButtonDelete onClick={() => onRemoveWaypoint(props.index) } />
        </div>
    );
}

// ****************************************************************************

const onWaypointMoved = ((activeId: number, overId: number) => {
    const route = useRouteStore.getState().activeObject;
    if (!route) return;
    const oldIndex = route.getWaypointIndexById(activeId);
    const newIndex = route.getWaypointIndexById(overId);
    Route.updateRoute(route => route.moveWaypointIndex(oldIndex, newIndex));
});

const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && (active.id !== over.id))
        onWaypointMoved(active.id as number, over.id as number);
};

// ****************************************************************************

export default function WaypointList(props: { route: Route }) {
    const { route } = props;
    const selectedWaypoint = useGui(state => state.selectedWaypoint);

    const dndSensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {
                distance: 8,
            },
        }),
        useSensor(TouchSensor, {
            activationConstraint: {
                delay: 250,
                tolerance: 5,
            },
        }),
    );

    return (
        <WaypointListStyle onClick={() => useGui.getState().resetSelectedWaypoint()}>
            <DndContext sensors={dndSensors} onDragEnd={onDragEnd} >
                <SortableContext items={route.waypoints} strategy={verticalListSortingStrategy} >
                    { route.waypoints.map((wp, index) =>
                        <WaypointListItem wp={wp} index={index} key={wp.id}
                            isActive={index===selectedWaypoint}
                            isLast={index >= route.waypoints.length-1}
                        /> )
                    }
                </SortableContext>
            </DndContext>
        </WaypointListStyle>
    );
};
