
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";

import useRouteStore from "../../db/routedb";
import useMapStore from "../../stores/mapstore";
import useRdgStore from "../../stores/Rdg";

import DataPlot from "../GuiElements/DataPlot";
import { PlotColor, ResultPlotColor, ResultPlots, ResultPlotType } from "./ResultPlotConfig";


export default function PlotElevationProfile()
{
    const data = useRdgStore((state) => state.plotData);
    const config = ResultPlots[ResultPlotType.ELEVATION_PROFILE];
    const colors = data.map((d) => getSlopeColorIndex(d.s));

    const onClick = (state: CategoricalChartState) => {
        const route = useRouteStore.getState().activeObject;
        const activePayload = state.activePayload;
        if (!route || !activePayload) return;
        const payload = activePayload[0].payload;
        const latlng = payload.c;
        useMapStore.getState().setMapLocation(latlng);
    }

    return (
        <div className="plot-elevation">
            <DataPlot
                data={data} colors={colors} thumbnail={true}
                axes={config.axes} rangeMin={config.rangeMin} rangeMax={config.rangeMax}
                onClick={onClick}
            />
        </div>
    );
}


export function getSlopePlotColor(slope: number)
{
    return ResultPlotColor[getSlopeColorIndex(slope)];
}

function getSlopeColorIndex(slope: number) {
    if (slope < 3) return PlotColor.Green;
    if (slope < 6) return PlotColor.Yellow;
    if (slope < 12) return PlotColor.Orange;
    if (slope < 18) return PlotColor.Red;
    return PlotColor.Deepred;
}