
import { Route } from "../../types/route";

import useModalDialog from "../../stores/modalstore";
import { CancelButton, CloseButton } from "../GuiElements/modaldialog";
import { DialogButton } from "../../types/modal";

// ****************************************************************************

export function showDialogRouteMaxpoints()
{
    useModalDialog.getState().show(
        <p>
            Die Route hat bereits die maximale Anzahl von {Route.MAX_WAYPOINTS} Wegpunkten.<br/>
            Bitte lösche einen Wegpunkt, bevor Du einen neuen hinzufügst.
        </p>,
        [ CloseButton ]
    );
}

// ****************************************************************************

export function showDialogConfirmDelete(what: string | React.ReactElement, action: () => void)
{
    useModalDialog.getState().show(
        <p>
            Bitte bestätige, dass Du {what} wirklich löschen möchtest.<br/>
            Der Lösch-Vorgang lässt sich nicht rückgängig machen.
        </p>,
        [
            new DialogButton('Endgültig Löschen', action, 2),
            CancelButton,
        ]
    );
}
