
import { createStore } from "./zustand";
import { RiderEffort } from "../RiderEffort";
import { Coordinate, RouteInfo } from "../types/route";
import { MixedPredictionResults, PlotDataDto, PredictionResultDto, PredictionResults, RoutingResultDto } from "../Compute/ComputeResults";
import { ResultPlotData } from "../Components/Results/ResultPlotConfig";


// these need not be part of state, only those for the current support level

let gPredictionResults: PredictionResultDto | null = null;

export type State = {
    routeInfo: RouteInfo | null;
    routeCoors: Coordinate[] | null;
    plotData: ResultPlotData[];
    routePlotData: PlotDataDto | null;
    predictionResults: PredictionResults[];
    mixedPredictionResults: MixedPredictionResults[];
    setRoutingResults: (results: RoutingResultDto | null) => void;
    setPredictionResults: (results: PredictionResultDto | null) => void;
    setPlotData: (data: ResultPlotData[]) => void;

    supportModeIndex: number;
    desiredRiderEffortSelection: RiderEffort;
    additionalWeight: number;
    setSupportModeIndex: (value: number) => void;
    setDesiredRiderEffortSelection: (riderEffort: RiderEffort) => void;
    setAdditionalWeight: (value: number) => void;
};


const useRdgStore = createStore<State>((set, get) => ({
    routeInfo: null,
    routeCoors: null,
    plotData: [],
    routePlotData: null,
    predictionResults: [],
    mixedPredictionResults: [],

    setRoutingResults: (results) => {
        if (results == null) {
            set((state) => {
                state.routeInfo = null;
                state.routeCoors = null;
                state.plotData = [];
                state.routePlotData = null;
                state.predictionResults = [];
                state.mixedPredictionResults = [];
            });
            return;
        }
        results.distance = results.distance.map(d => d / 1000);
        // FIXME: use RoutingResultsDto, remove RouteInfo and PlotDataDto
        const routeInfo: RouteInfo = {
            routeLat: results.lat,
            routeLong: results.lng,
            distanceM: results.distanceM,
            uphillM: results.uphillM,
            downhillM: results.downhillM,
        };
        const routePlotData: PlotDataDto = {
            distance: results.distance,
            altitude: results.altitude,
            slope: results.slope,
        };
        set((state) => {
            state.routeInfo = routeInfo;
            state.routeCoors = getRouteCoordinates(routeInfo);
            state.routePlotData = routePlotData;
        });
    },

    setPredictionResults: (results) => {
        if (results == null) {
            set((state) => {
                state.predictionResults = [];
                state.mixedPredictionResults = [];
            });
            return;
        }
        set((state) => {
            state.predictionResults = results.predictionResults;
            state.mixedPredictionResults = results.mixedPredictionResults;
        });
    },

    setPlotData: (data) => {
        set((state) => {
            state.plotData = data;
        });
    },

    supportModeIndex: -1,
    desiredRiderEffortSelection: RiderEffort.Normal,
    additionalWeight: 0,

    setSupportModeIndex: (value: number) => {
        set((state) => {
            state.supportModeIndex = value;
        });
    },

    setDesiredRiderEffortSelection: (riderEffort) => {
        set((state) => {
            state.desiredRiderEffortSelection = riderEffort;
        });
    },

    setAdditionalWeight: (value: number) => {
        set((state) => {
            state.additionalWeight = (value != null)? value: 0;
        });
    },
}));

function getRouteCoordinates(route: RouteInfo) {
    let result: Coordinate[] = [];
    console.log("Adding waypoints from route coordinates");
    for (let i=0; i<route.routeLat.length; i++) {
        result.push({ lat: route.routeLat[i], lng: route.routeLong[i] });
    }
    return result;
}


export function getPredictionResults(state: State) {
    if (!state.predictionResults.length) return null;
    if (state.supportModeIndex < 0) {
        return state.mixedPredictionResults[state.desiredRiderEffortSelection];
    } else {
        return state.predictionResults[state.supportModeIndex];
    }
};

export function getSupportMode(state: State, routeIndex: number) {
    if (state.supportModeIndex < 0) {
        return getMixedSupportMode(state, routeIndex);
    } else {
        return state.supportModeIndex;
    }
};

function getMixedSupportMode(state: State, routeIndex: number)
{
    const modeIndexRle = state.mixedPredictionResults[state.desiredRiderEffortSelection]?.modeIndexRle;
    if (!modeIndexRle) return null;
    for (let i=0; i<modeIndexRle.length; i++) {
        const [ mi, run ] = modeIndexRle[i];
        routeIndex -= run;
        if (routeIndex <= 0)
            return mi;
    }
    return null;
}

export default useRdgStore;
