
import styled from "styled-components";

export const DescriptionList = styled.dl`
    display: grid;
    grid-template-columns: 6em 1fr;
`;

export const DescriptionTag = styled.dt`
    grid-column: 1;
    font-weight: bold;
`;

export const DescriptionValue = styled.dd`
    grid-column: 2;
`;
