
import { createContext, useContext, useState } from "react";

// ****************************************************************************

interface BrowserInfoData {
    width: number;
    height: number;
    isMobile: boolean;
}

function getBrowserInfo()
{
    // note that due to initial-scale and zoom mobile values of
    // window.innerWidth/Height may be wrong
    // these are the values without scrollbar
    const width = document.documentElement.clientWidth;
    const height = document.documentElement.clientHeight;

    return {
        width: width,
        height: height,
        isMobile: (width < 760),
    };
}

// ****************************************************************************

type Props = React.PropsWithChildren<{}>;

const BrowserInfo = createContext<BrowserInfoData>(getBrowserInfo());

export function BrowserInfoProvider(props: Props)
{
    const { children } = props;

    const [ browserInfo, setBrowserInfo ] = useState(getBrowserInfo());

    window.onresize = () => { setBrowserInfo(getBrowserInfo); }

    return (
        <BrowserInfo.Provider value={browserInfo}>
            {children}
        </BrowserInfo.Provider>
    );
}

// ****************************************************************************

export default function useBrowserInfo() {
    return useContext(BrowserInfo);
}
