
import { UserLoginData } from "../../types/user";
import useUserStore, { LoginState } from "../../stores/userstore";

import { useForm } from 'react-hook-form';

import { Link } from '../GuiElements/Menu';
import { Checkbox, PasswordInput, SubmitButton, TextInput } from '../GuiElements/forms';
import { getLoginErrorMessage } from "./UserLoginError";


const onLogin = (loginData: UserLoginData) => {
    useUserStore.getState().login(loginData);
}

const onDemoLogin = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    useUserStore.getState().demoLogin();
}

const onShowResetPassword = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    const userStore = useUserStore.getState();
    userStore.setLoginError(null);
    userStore.setLoginState(LoginState.PASSWORD_RESET_SHOW);
}


export default function UserLoginForm() {
    const loginState = useUserStore((store) => store.loginState);
    const loginError = useUserStore((store) => store.loginError);

    const { register, handleSubmit, formState: { errors }, } = useForm<UserLoginData>({
        mode: "onChange",
        shouldUseNativeValidation: true,
    });

    if (loginState !== LoginState.LOGGED_OUT) return null;

    return (
        <form onSubmit={handleSubmit(onLogin)}>
            <h2>Anmelden</h2>
            { loginError && getLoginErrorMessage(loginError) }
            <TextInput error={errors.userName?.message}
                label="Benutzername oder E-Mail"
                {...register("userName", { required: "Bitte Benutzernamen oder E-Mail-Adresse angeben." })}
            />
            <PasswordInput error={errors.password?.message}
                label="Passwort"
                {...register("password", { required: "Bitte Account-Passwort angeben." })}
            />
            <Checkbox error={errors.remember?.message}
                label="Anmeldung speichern"
                {...register("remember")}
            />
            <SubmitButton/>

            <p><Link onClick={onShowResetPassword}>Passwort vergessen?</Link></p>
            <p style={{marginTop: '2em'}}><Link onClick={onDemoLogin}>Als Demo Benutzer anmelden</Link></p>
        </form>
    );
}
