
import { DbObject } from "../db/collection";

// ****************************************************************************

export enum EbikeType {
    Mountain = "mountain",
    Trekking = "trekking",
    City = "city",
    Racing = "racing",
}

export const EbikeTypeStrings: Record<EbikeType, string> = {
    [EbikeType.Mountain]: "Mountainbike",
    [EbikeType.Trekking]: "Trekkingrad",
    [EbikeType.City]: "Citybike",
    [EbikeType.Racing]: "Rennrad",
};

// ****************************************************************************

export enum TireType {
    Mountain = "mountain",
    Standard = "standard",
    Racing = "racing",
};

export const TireTypeStrings: Record<TireType, string> = {
    [TireType.Mountain]: "Mountainbike-Reifen",
    [TireType.Standard]: "Standard-Reifen",
    [TireType.Racing]: "Rennrad-Reifen",
};

// ****************************************************************************

export enum ShiftboxManufacturer {
    Shimano = "shimano",
    SRAM = "sram",
};

export const ShiftboxManufacturerStrings: Record<ShiftboxManufacturer, string> = {
    [ShiftboxManufacturer.Shimano]: "Shimano",
    [ShiftboxManufacturer.SRAM]: "SRAM",
};

// ****************************************************************************

class Shiftbox
{
    manufacturer: ShiftboxManufacturer = ShiftboxManufacturer.Shimano;
    gearCount: number = 10;

    constructor(obj?: Shiftbox) {
        if (obj) {
            this.manufacturer = obj.manufacturer;
            this.gearCount = obj.gearCount;
        }
    }
};

export class Ebike extends DbObject
{
    ebikeType: EbikeType = EbikeType.Mountain;
    tireType: TireType = TireType.Mountain;
    tireSizeIn: number = 26;
    motor: string = '623c90b3ba13c95b8964cfd9';
    batteryCapacityWh: number = 500;
    shiftbox: Shiftbox = new Shiftbox();

    constructor(obj?: Ebike) {
        super(obj);
        if (obj) {
            this.ebikeType = obj.ebikeType;
            this.tireType = obj.tireType;
            this.tireSizeIn = obj.tireSizeIn;
            this.motor = obj.motor;
            this.batteryCapacityWh = obj.batteryCapacityWh;
            this.shiftbox = new Shiftbox(obj.shiftbox);
        }
    }

    static getDefault() {
        const ebike = new Ebike();
        ebike.name = 'Standard E-Bike';
        return ebike;
    }

    static getNew() {
        const ebike = new Ebike();
        ebike.name = 'Neues E-Bike';
        return ebike;
    }
};
