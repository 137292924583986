
import { useForm } from 'react-hook-form';

import { UserChangePasswordDto, isDemoUser } from "../../types/user";
import useUserStore from "../../stores/userstore";
import { PasswordInput, SubmitButton } from '../GuiElements/forms';
import { getLoginErrorMessage } from "./UserLoginError";


export default function UserPasswordChangeForm() {
    const userData = useUserStore((store) => store.data);
    const message = useUserStore((store) => store.passwordMessage);

    const { register, handleSubmit, reset, formState: { errors }, } = useForm<UserChangePasswordDto>({
        mode: "onChange",
        shouldUseNativeValidation: true,
    });

    if (isDemoUser(userData!)) {
        return (<>
            <h2>Passwort ändern</h2>
            <p>Das Passwort des Demo-Benutzers kann nicht geändert werden.</p>
        </>);
    }

    const onChangePasword = (data: UserChangePasswordDto) => {
        useUserStore.getState().changePassword(data);
        reset();
    }

    return (
        <form onSubmit={handleSubmit(onChangePasword)}>
            <h2>Passwort ändern</h2>
            { message && getLoginErrorMessage(message) }
            <PasswordInput error={errors.currentPassword?.message}
                label="Aktuelles Passwort"
                {...register("currentPassword", { required: "Bitte gib Dein aktuelles Passwort ein." })}
            />
            <PasswordInput error={errors.password?.message}
                label="Neues Passwort"
                {...register("password", { required: "Bitte lege ein neues Passwort fest." })}
            />
            <PasswordInput error={errors.password2?.message}
                label="Wiederholung des Passworts"
                {...register("password2", { required: "Bitte gib Dein neues Passwort nochmal ein." })}
            />
            <SubmitButton label="Passwort ändern"/>
        </form>
    );
}
