
import { useMemo, useRef } from "react";
import { renderToString } from "react-dom/server";
import { Polyline, useMap, useMapEvent } from "react-leaflet";
import L from "leaflet";

import useGui from "../../stores/guistore";
import useRdgStore from "../../stores/Rdg";
import useRouteStore from "../../db/routedb";

import { Coordinate, Route, Waypoint } from "../../types/route";
import { MapBtnCenter, MapBtnLayers } from "./MapButtons";
import MapDisplay from "./MapDisplay";
import { MapWaypointMarker } from "./MapMarker";
import PlotElevationProfile from "../Results/PlotElevationProfile";
import { PlotColor, ResultPlotColor } from "../Results/ResultPlotConfig";
import { RoutePopup, getRoutePopupData } from "../Routes/RoutePopup";

import "./MapEditor.css";

// ****************************************************************************

function RouteCoordinates(props: { routeCoors: Coordinate[] | null}) {
    const { routeCoors } = props;

    const map = useMap();

    const routeInfo = useRdgStore((state) => state.routeInfo);
    const routePlotData = useRdgStore((state) => state.routePlotData);
    const supportModeIndex = useRdgStore((state) => state.supportModeIndex);
    const desiredRiderEffortSelection = useRdgStore((state) => state.desiredRiderEffortSelection);
    const predictionResults = useRdgStore((state) => state.predictionResults);
    const mixedPredictionResults = useRdgStore((state) => state.mixedPredictionResults);

    const eventHandlers = {
        click: (ev: L.LeafletMouseEvent) => {
            L.DomEvent.stop(ev);
            useGui.getState().resetSelectedWaypoint();
            if (!map || !routeCoors || !routePlotData)
                return;
            const routeIndex = getClosestRoutePoint(ev.latlng, routeCoors);
            if (routeIndex < 0) return;
            // distance is 20m per point, that's 0.02 km
            const realDistance = routeIndex * 0.02;
            // find closest distance in plot data
            let plotIndex = 0, minDiff = 100000;
            for (let i=0; i<routePlotData.distance.length; i++) {
                const d = routePlotData.distance[i];
                const diff = Math.abs(d - realDistance);
                if (diff < minDiff) {
                    plotIndex = i;
                    minDiff = diff;
                }
            }
            // show popup
            const latlng = routeCoors[routeIndex];
            let popup = L.popup().setLatLng(latlng).setContent(
                renderToString(<RoutePopup data={getRoutePopupData(routeIndex, plotIndex)} />));
            popup.openOn(map);
        }
    };

    if (routeInfo && routeCoors) {

        let segments = [];

        if ((predictionResults.length > 0) && mixedPredictionResults) {
            if (supportModeIndex < 0) {
                // FIXME: compute this only, if significant values change (useEffect?)
                const modeIndexRle = mixedPredictionResults[desiredRiderEffortSelection].modeIndexRle;
                let rci = 0;
                for (let i=0; i<modeIndexRle.length && rci<routeCoors.length; i++) {
                    const [ mi, run ] = modeIndexRle[i];
                    const segment = {
                        coors: [] as Coordinate[],
                        color: ResultPlotColor[mi as PlotColor],
                    }
                    for (let k=0; k<run && rci<routeCoors.length; k++) {
                        segment.coors.push(routeCoors[rci++]);
                    }
                    segments.push(segment);
                }
            } else {
                segments.push({
                    coors: routeCoors,
                    color: ResultPlotColor[supportModeIndex as PlotColor],
                })
            }
        } else {
            segments.push({coors: routeCoors, color: 'blue'});
        }

        return (
            <> {
                segments.map((value, index) =>
                    <Polyline key={`route-${index}-${value.color}`}
                        color={value.color} opacity={1} weight={3}
                        positions={value.coors}
                        eventHandlers={eventHandlers}
                    />
                )
          } </>
        );
    }

    return null;
}

// ****************************************************************************

function getClosestRoutePoint(latlng: L.LatLng, route: Coordinate[]) {
    if (route.length === 0) return -1;
    let minIndex = 0;
    let minDist = latlng.distanceTo(route[0]);
    for (let i=1; i<route.length; i++) {
        const p = route[i];
        const d2 = latlng.distanceTo(p);
        if (d2 < minDist) {
            minIndex = i;
            minDist = d2;
        }
    }
    return minIndex;
}

// ****************************************************************************

function addButton(div: HTMLDivElement, text: string, onClick: () => void) {
    const btn = L.DomUtil.create('button', 'map-popup-button', div);
    btn.innerHTML = text;
    btn.onclick = () => {
        onClick();
    };

}

function updateRoute(latlng: L.LatLng, update: (route: Route, wp: Waypoint) => void) {
    const wp = new Waypoint(latlng.lat, latlng.lng);
    Route.updateRoute(route => update(route, wp));
}

// ****************************************************************************

function MapClickEventHandler() {
    const route = useRouteStore((state) => state.activeObject);
    const numWaypoints = route? route.waypoints.length: 0;

    let popup: L.Popup;

    const map = useMapEvent(
        'click', (ev: L.LeafletMouseEvent) => {
            L.DomEvent.stop(ev);
            useGui.getState().resetSelectedWaypoint();
            const content = L.DomUtil.create('div');
            if (numWaypoints <= 0) {
                addButton(content, 'Start hier festlegen', () => handlers.onSetStart(ev.latlng, popup));
            }
            else if (numWaypoints === 1) {
                addButton(content, 'Ziel hier festlegen', () => handlers.onSetTarget(ev.latlng, popup));
                addButton(content, 'Als neuen Start festlegen', () => handlers.onSetStart(ev.latlng, popup));
            }
            else {
                addButton(content, 'Wegpunkt hier hinzufügen', () => handlers.onAddWaypoint(ev.latlng, popup));
                addButton(content, 'Als neuen Start festlegen', () => handlers.onSetStart(ev.latlng, popup));
                addButton(content, 'Als neues Ziel festlegen', () => handlers.onSetTarget(ev.latlng, popup));
            }
            popup = L.popup().setLatLng(ev.latlng).setContent(content);
            popup.openOn(map);
        }
    );

    const handlers = useMemo(() => ({
        onSetStart: (latlng: L.LatLng, popup: L.Popup) => {
            map.closePopup(popup);
            updateRoute(latlng, (route, wp) => route.setStart(wp))
        },

        onSetTarget: (latlng: L.LatLng, popup: L.Popup) => {
            map.closePopup(popup);
            updateRoute(latlng, (route, wp) => route.setTarget(wp))
        },

        onAddWaypoint: (latlng: L.LatLng, popup: L.Popup) => {
            map.closePopup(popup);
            updateRoute(latlng, (route, wp) => route.addWaypoint(wp))
        },
    }),
    [map]);

    return null;
}

// ****************************************************************************

export default function MapEditor()
{
    const route = useRouteStore((state) => state.activeObject);
    const routeCoors = useRdgStore((state) => state.routeCoors);

    const buttonDiv = useRef<HTMLDivElement>(null);
    if (buttonDiv.current)
        L.DomEvent.disableClickPropagation(buttonDiv.current);

    return (
        <div className="map-editor">
            <MapDisplay>
                <MapClickEventHandler/>
                <RouteCoordinates routeCoors={routeCoors} />
                { route && !routeCoors &&
                    <Polyline key="activeRoutePolyline" color="red" opacity={0.5} weight={5} positions={route.waypoints} />
                }
                { route && route.waypoints.map((wp, index) => <MapWaypointMarker key={wp.id} index={index} waypoint={wp} />) }
                <div ref={buttonDiv} className="map-buttons leaflet-top leaflet-right">
                    <MapBtnLayers/>
                    <MapBtnCenter/>
                </div>
            </MapDisplay>

            <PlotElevationProfile/>
        </div>
    );
}
