
import styled from "styled-components";
import { faCircleInfo, faCrosshairs, faEye, faEyeSlash, faLayerGroup, faShareNodes, faSquareShareNodes, faTrash, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// ****************************************************************************

export function IconMapCenter() {
    return <FontAwesomeIcon icon={faCrosshairs}/>;
}

export function IconMapLayers() {
    return <FontAwesomeIcon icon={faLayerGroup}/>;
}

// ****************************************************************************

const ButtonStyle = styled.div`
    display: inline-block;
    font-size: 1.2em;
    cursor: pointer;
`;

interface ButtonProps {
    valid?: boolean;
    onClick?: () => void;
}

function Button(props: { icon: IconDefinition } & ButtonProps) {

    const onButtonClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onClick?.();
    }

    return (
        <ButtonStyle onClick={onButtonClick}>
            <FontAwesomeIcon icon={props.icon}/>
        </ButtonStyle>
    );
}

// ****************************************************************************

export function ButtonDelete(props: ButtonProps) {
    return <Button icon={faTrash} {...props}></Button>;
}

export function ButtonInfo(props: ButtonProps) {
    return <Button icon={faCircleInfo} {...props}></Button>;
}

export function ButtonShare(props: ButtonProps) {
    return <Button icon={faShareNodes} {...props}></Button>;
}

export function ButtonUnShare(props: ButtonProps) {
    return <Button icon={faSquareShareNodes} {...props}></Button>;
}

export function ButtonShowHide(props: ButtonProps) {
    const icon = props.valid? faEye: faEyeSlash;
    return <Button icon={icon} {...props}></Button>;
}
