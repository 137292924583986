
import { Route, Routes } from "react-router-dom";

import { ThemeProvider } from "styled-components";

import Layout from "./Layout/Layout";

import useGui from "./stores/guistore";
import useUserStore, { LoginState } from "./stores/userstore";

import MainMapPage from "./Components/Main/MainPage";
import UserLoginPage from "./Components/User/UserLoginPage";
import UserProfilePage from "./Components/User/UserProfilePage";
import UserTokenPage from "./Components/User/UserTokenPage";


import "./App.css";


export default function App() {
    const theme = useGui((state) => state.theme);
    const loginState = useUserStore((state) => state.loginState);

    // we need to check for token pages before showing the login page
    const path = window.location.pathname;
    if (path.startsWith('/t/')) {
        return <Layout><UserTokenPage token={path.substring(3)}/></Layout>;
    }

    if (loginState !== LoginState.LOGGED_IN) {
        return <Layout><UserLoginPage/></Layout>;
    }

    return (
        <ThemeProvider theme={theme}>
            <Layout>
                <Routes>
                    <Route path='/profile' element={<UserProfilePage/>} />
                    <Route path='*' element={<MainMapPage/>} />
                </Routes>
            </Layout>
        </ThemeProvider>
    );
}
