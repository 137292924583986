
import useUserStore, { LoginState } from "../../stores/userstore";

import NarrowContent from "../../Layout/NarrowContent";
import LayoutColumns from "../../Layout/LayoutColumns";
import UserLoginForm from './UserLoginForm';
import UserPasswordResetForm from './UserPasswordResetForm';
import UserRegistrationForm from './UserRegistrationForm';


export default function UserLoginPage() {
    const loginState = useUserStore((store) => store.loginState);

    switch (loginState) {

        case LoginState.LOGGING_OUT:
            return (
                <NarrowContent>
                    <p>Deine Sitzung wird beendet.</p>
                </NarrowContent>
            );

        case LoginState.AUTO_LOGIN:
            return (
                <NarrowContent>
                    <p>Die Verbindung mit dem Server wird hergestellt ...</p>
                </NarrowContent>
            );

        case LoginState.PASSWORD_RESET_REQUESTED:
            return (
                <NarrowContent>
                    <p>Die E-Mail wird versendet ...</p>
                </NarrowContent>
            );

        case LoginState.PASSWORD_RESET_SENT:
            return (
                <NarrowContent>
                    <p>Die E-Mail wurde erfolgreich versendet. Bitte überprüfe Dein E-Mail-Postfach und folge dem Link in der E-Mail.</p>
                </NarrowContent>
            );

        case LoginState.ACCOUNT_DELETED:
            return (
                <NarrowContent>
                    <p>Der Benutzer-Account wurde gelöscht.</p>
                </NarrowContent>
            );
    }

    return (
        <NarrowContent>
            <div>
                <p>
                    Wenn Du persönliche Einstellungen speichern willst,
                    musst Du Dich unten mit Deinem Benutzernamen und Passwort anmelden.
                </p>
                <p>
                    Wenn Du noch keine Zugangsdaten hast, kannst Du Dich unten kostenlos registrieren.
                </p>
            </div>

            <LayoutColumns>
                <div>
                    <UserLoginForm/>
                    <UserPasswordResetForm/>
                </div>
                <div>
                    <UserRegistrationForm/>
                </div>
            </LayoutColumns>
        </NarrowContent>
    );
}
