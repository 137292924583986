
import { Motor } from "../types/motor";

import DbCollection from "../db/collection";
import { createStore } from "../stores/zustand";
import { addLoginStateObserver, LoginState } from "../stores/userstore";

// ****************************************************************************

class MotorCollection extends DbCollection<Motor, Motor>
{
    constructor() {
        super("motor");
    }

    fromDto(dto: Motor): Motor {
        return dto;
    }

    toDto(object: Motor): Motor {
        return object;
    }
}

// ****************************************************************************

const MotorDb = new MotorCollection();

const useMotorStore = createStore(MotorDb.storeConfig);

addLoginStateObserver((newState, oldState) => {
    if (newState === LoginState.LOGGED_IN) {
        useMotorStore.getState().updateList();
    } else if (newState === LoginState.LOGGED_OUT) {
        useMotorStore.getState().clear();
    }
});

export default useMotorStore;
