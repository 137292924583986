
import { useForm } from 'react-hook-form';

import { UserAccountDeleteDto, isDemoUser } from "../../types/user";
import useUserStore from "../../stores/userstore";
import { Checkbox, PasswordInput, SubmitButton } from '../GuiElements/forms';
import { getLoginErrorMessage } from "./UserLoginError";


export default function UserAccountDeleteForm() {
    const userData = useUserStore((store) => store.data);
    const message = useUserStore((store) => store.deleteMessage);

    const { register, handleSubmit, reset, formState: { errors }, } = useForm<UserAccountDeleteDto>({
        mode: "onChange",
        shouldUseNativeValidation: true,
    });

    if (isDemoUser(userData!)) {
        return (<>
            <h2>Account löschen</h2>
            <p>Der Demo-Account kann nicht gelöscht werden.</p>
        </>);
    }

    const onDeleteAccount = (data: UserAccountDeleteDto) => {
        useUserStore.getState().deleteAccount(data);
        reset();
    }

    return (
        <form onSubmit={handleSubmit(onDeleteAccount)}>
            <h2>Account löschen</h2>
            <p>
                Um Deinen Account komplett zu löschen, benutze bitte das kurze Formular unten.
                Bitte bedenke, dass diese Aktion nicht rückgängig gemacht werden kann.
            </p>
            { message && getLoginErrorMessage(message) }
            <PasswordInput error={errors.password?.message}
                label="Passwort"
                {...register("password", { required: "Bitte das aktuelle Passwort eingeben." })}
            />
            <Checkbox error={errors.deletePublic?.message}
                label="Auch mit der Community geteilte Routen sollen gelöscht werden."
                {...register("deletePublic")}
            />
            <SubmitButton label="Account endgültig löschen"/>
        </form>
    );
}
