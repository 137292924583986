
import { Rider } from "../types/rider";

import DbCollection from "../db/collection";
import { createStore } from "../stores/zustand";
import { addLoginStateObserver, LoginState } from "../stores/userstore";

// ****************************************************************************

class RiderCollection extends DbCollection<Rider, Rider>
{
    constructor() {
        super("rider");
    }

    fromDto(dto: Rider): Rider {
        return dto;
    }

    toDto(object: Rider): Rider {
        return object;
    }

    setIsEditing(isEditing: boolean) {
        useRiderStore.getState().setIsEditing(isEditing);
    }
}

// ****************************************************************************

export const RiderDb = new RiderCollection();

const useRiderStore = createStore(RiderDb.storeConfig);

addLoginStateObserver((newState, oldState) => {
    if (newState === LoginState.LOGGED_IN) {
        useRiderStore.getState().updateList();
    } else if (newState === LoginState.LOGGED_OUT) {
        useRiderStore.getState().clear();
    }
});

export default useRiderStore;
