import { Ebike, ShiftboxManufacturerStrings } from "../../types/ebike";
import useEbikeStore, { EbikeDb } from "../../Database/EbikeCollection";
import useMotorStore from "../../Database/MotorCollection";

import { AccordionPanel } from "../GuiElements/accordion";
import { Select } from "../GuiElements/forms";
import { Link, Menu } from "../GuiElements/Menu";
import { GridContainer, GridPair } from "../../GridComponents";
import { showDialogConfirmDelete } from "../dialogs/edit-dialogs";

// ****************************************************************************

function deleteEbike(ev: React.MouseEvent, name: string)
{
    ev.preventDefault();
    ev.stopPropagation();
    showDialogConfirmDelete(
        <span>das E-Bike <b>{name}</b></span>,
        () => useEbikeStore.getState().delete(),
    );
}

// ****************************************************************************

const EbikeControl = () => {
    const ebikeList = useEbikeStore((state) => state.list);
    const ebike = useEbikeStore((state) => state.activeObject);
    const motor = useMotorStore((state) => state.activeObject);

    const hasData = (ebikeList && ebike);

    let label = "E-Bike";
    if (ebike) {
        label += ` (${ebike.name})`;
    }

    let tireSizeIn, gearCount, shiftboxManufacturer, batteryCapacityWh;
    if (hasData) {
        tireSizeIn = ebike.tireSizeIn;
        gearCount = ebike.shiftbox.gearCount;
        shiftboxManufacturer = ebike.shiftbox.manufacturer;
        batteryCapacityWh = ebike.batteryCapacityWh;
    } else {
        const e = Ebike.getNew();
        tireSizeIn = e.tireSizeIn;
        gearCount = e.shiftbox.gearCount;
        shiftboxManufacturer = e.shiftbox.manufacturer;
        batteryCapacityWh = e.batteryCapacityWh;
    }

    let ebikeSelect;
    if (hasData) {
        ebikeSelect = (
            <Select
                options={ebikeList.map((value) => ({key: value.id!, value: value.name}))}
                onChange={(ev) => useEbikeStore.getState().setById(ev.target.value)}
            />
        );
    } else {
        ebikeSelect = "Standard E-Bike";
    }

    return (
        <AccordionPanel heading={label}>

            <div>
                {ebikeSelect}
                <Menu>
                    { hasData && <Link onClick={() => EbikeDb.startEditing(new Ebike(ebike))}>Ändern</Link> }
                    { hasData && <Link onClick={(ev) => deleteEbike(ev, ebike.name)}>Löschen</Link> }
                    <Link onClick={() => EbikeDb.startEditing(Ebike.getNew())}>Neu</Link>
                </Menu>
            </div>

            <GridContainer>
                <GridPair left="Radgröße" right={`${tireSizeIn} Zoll`} />
                <GridPair left="Schaltung" right={`${gearCount} Gänge, ${ShiftboxManufacturerStrings[shiftboxManufacturer]}`} />
                <GridPair left="E-Bike Antrieb" right={motor? motor.name: ''} />
                <GridPair left="Akkukapazität" right={`${Math.round(batteryCapacityWh)} Wh`} />
            </GridContainer>
        </AccordionPanel>
    );
};

export default EbikeControl;
