
import useModalDialog from "../../stores/modalstore";
import useUserStore from "../../stores/userstore";
import { DialogButton } from "../../types/modal";

export function showAcceptResearchDialog()
{
    useModalDialog.getState().show(
        <div>
            <p>
                Dies ist ein Projekt der Hochschule Karlsruhe und das Ergebnis von
                aktiver Forschung an Algorithmen zur Vorhersage des Energiebedarfs.
            </p>
            <p>
                Bitte erlauben Sie uns, die zu Ihrem Account gespeicherten Daten zu
                Forschungszwecken auszuwerten. Vielen Dank!
            </p>
        </div>,
        [
            // new DialogButton('Akzeptiern', () => Route.copyActiveRoute().then(() => resolve(true)), 2),
            new DialogButton('Akzeptiern', () => useUserStore.getState().setAcceptResearch(true), 2),
            new DialogButton('Ablehnen', () => {}),
        ]
    );
}
