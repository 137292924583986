
import React, { useState } from "react";

import useRouteStore, { RouteDb } from "../../db/routedb";

import { FormGroupRow, NumericInput, SaveAbortButtons, TextInput } from "../GuiElements/forms";
import { Button, ButtonBoxFill } from "../GuiElements/modaldialog";
import { Route, Waypoint } from "../../types/route";
import { showRouteSelection } from "../Routes/RouteSelection";
import WaypointList from "./WaypointList";

// ****************************************************************************

const onSelectRoute = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    useRouteStore.getState().updateList().then(() => showRouteSelection());
};

const onNewRoute = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    RouteDb.createNewEmptyRoute();
};

const onSetRouteName = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const name = ev.target.value;
    Route.updateRoute(route => { route.name = name; });
}

const onReverseDirection = () => {
    Route.updateRoute(route => route.reverseDirection());
}

const onSave = () => {
    useRouteStore.getState().saveEdit();
};

const onAbort = () => {
    useRouteStore.getState().cancelEdit();
};

const onAddWaypointLatLng = (lat: number, lng: number) => {
    if (!lat || !lng) return;
    const wp = new Waypoint(lat, lng);
    Route.updateRoute(route => route.addWaypoint(wp));
}

// ****************************************************************************

function RouteSelect() {
    return (
        <ButtonBoxFill>
            <Button color={2} onClick={onSelectRoute}>Meine Routen</Button>
            <Button color={2} onClick={onNewRoute}>Neue Route</Button>
        </ButtonBoxFill>
    );
}

// ****************************************************************************

export default function RouteSettings() {
    const route = useRouteStore((state) => state.activeObject);

    const [ waypointLat, setWaypointLat ] = useState<number>(0);
    const [ waypointLng, setWaypointLng ] = useState<number>(0);

    if (!route) return <RouteSelect/>;

    return (
        <>
            <div>
                <RouteSelect/>

                <TextInput label="Routenname" value={route.name}
                    onChange={onSetRouteName}
                />

                <ButtonBoxFill>
                    <Button
                        onClick={onReverseDirection}>
                        Richtung umkehren
                    </Button>
                </ButtonBoxFill>

                <SaveAbortButtons onSave={onSave} onAbort={onAbort} />
            </div>

            <WaypointList route={route} />

            <div>
                <FormGroupRow label="Wegpunkt hinzufügen">
                    <NumericInput label="Breitengrad" value={waypointLat}
                        onChange={(ev) => setWaypointLat(parseFloat(ev.target.value))}
                    />
                    <NumericInput label="Längengrad" value={waypointLng}
                        onChange={(ev) => setWaypointLng(parseFloat(ev.target.value))}
                    />
                    {/* FIXME: check ranges: lat [-90,90] and lng [-180,180] */}
                    <Button onClick={() => onAddWaypointLatLng(waypointLat, waypointLng)}>
                        Hinzufügen
                    </Button>
                </FormGroupRow>
            </div>
        </>
    );
};
