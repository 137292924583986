
import { BrowserRouter } from "react-router-dom";
import styled from "styled-components";

import { BrowserInfoProvider } from "./BrowserInfo";
import Header from "./Header";
import Footer from "./Footer";

import "./Layout.css";

const Content = styled.div`
    width: 100%; height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export default function Layout(props: React.PropsWithChildren) {
    const { children } = props;

    return (
        <BrowserInfoProvider>
            <div id="layout">
                <BrowserRouter>
                    <Content>
                        <Header />
                        {children}
                        <Footer />
                    </Content>
                </BrowserRouter>
            </div>
        </BrowserInfoProvider>
    );
};
