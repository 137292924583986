
import { useEffect } from "react";
import { useForm } from "react-hook-form";

import NarrowContent from "../../Layout/NarrowContent";
import useUserStore from "../../stores/userstore";
import { UserNewPasswordDto } from "../../types/user";
import { PasswordInput, SubmitButton } from "../GuiElements/forms";
import { getLoginErrorMessage } from "./UserLoginError";

// ****************************************************************************

function ResetPasswordForm(props: { token: string }) {
    const { token } = props;

    const loginError = useUserStore((store) => store.loginError);

    const { register, getValues, handleSubmit, formState: { errors } } = useForm<UserNewPasswordDto>();

    const onSubmit = (data: UserNewPasswordDto) => {
        data.token = token;
        useUserStore.getState().newPassword(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <h2>Neues Passwort setzen</h2>
            { loginError && getLoginErrorMessage(loginError) }
            <PasswordInput error={errors.password?.message}
                label="Passwort"
                {...register("password", { required: "Das Passwort darf nicht leer sein." })}
            />
            <PasswordInput error={errors.password2?.message}
                label="Wiederholung des Passworts"
                {...register("password2", {
                    validate: (value) => {
                        const { password } = getValues();
                        return (value === password) || "Die Passwörter stimmen nicht überein!";
                    },
                    deps: "password",
                })}
            />
            <SubmitButton/>
        </form>
    );
}

// ****************************************************************************

export default function UserTokenPage(props: { token: string }) {
    const { token } = props;

    const userTokenType = useUserStore((state) => state.userTokenType);

    useEffect(() => {
        const state = useUserStore.getState();
        state.setUserTokenType(null);
        state.validateToken(token);
    },
    [token]);

    if (userTokenType == null) {
        return (
            <NarrowContent>
                <p>Das Token wird validiert.</p>
            </NarrowContent>
        );
    }
    else if (userTokenType === 'reset_password') {
        return (
            <NarrowContent>
                <div className="login-form-wrapper">
                    <ResetPasswordForm token={token} />
                </div>
            </NarrowContent>
        );
    }
    else {
        return (
            <NarrowContent>
                <p>Der Link ist leider ungültig.</p>
            </NarrowContent>
        );
    }
}
