
import { immerable } from "immer";
import { LatLngBoundsExpression } from "leaflet";

import { DbObject } from "../db/collection";
import useRouteStore, { RouteDb } from "../db/routedb";
import useUserStore from "../stores/userstore";
import { showDialogRouteMaxpoints } from "../Components/dialogs/edit-dialogs";
import { showDialogRouteCopy } from "../Components/dialogs/route-dialogs";

// ****************************************************************************

export interface Coordinate {
    lat: number;
    lng: number;
}

export class Waypoint {
    [immerable] = true;

    id: number = 0;
    lat: number = 0;
    lng: number = 0;

    constructor(lat: number, lng: number) {
        this.id = Waypoint.nextId++;
        this.lat = Waypoint.normalize(lat);
        this.lng = Waypoint.normalize(lng);
    }

    private static normalize(deg: number) {
        while (deg < -180) deg += 360;
        while (deg > 180) deg -= 360;
        return deg;
    }

    public static getDistance(wp0: Waypoint, wp1: Waypoint) {
        const dlat = wp0.lat - wp1.lat;
        const dlng = wp0.lng - wp1.lng;
        return Math.sqrt(dlat*dlat + dlng*dlng);
    }

    private static nextId: number = 1;
}

export class WaypointList {
    lat: number[] = [];
    lng: number[] = [];

    constructor(waypoints: Waypoint[]) {
        waypoints.forEach((wp) => {
            this.lat.push(wp.lat);
            this.lng.push(wp.lng);
        })
    }
}

// ****************************************************************************

// FIXME: should go into a file with result types
export interface RouteInfo {
    routeLat: number[];
    routeLong: number[];
    distanceM: number;
    uphillM: number;
    downhillM: number;
}

// ****************************************************************************

export class RouteDto extends DbObject {
    waypoints: WaypointList;

    constructor(obj: Route) {
        super(obj);
        this.waypoints = new WaypointList(obj.waypoints);
    }
}

export class Route extends DbObject
{
    static MAX_WAYPOINTS = 70;

    waypoints: Waypoint[];

    constructor(obj?: Route | RouteDto) {
        super(obj);
        this.waypoints = [];
        if (!obj || !obj.waypoints) return;
        if (Array.isArray(obj.waypoints)) {
            // From Route: duplicate waypoints
            obj.waypoints.forEach((wp) => this.waypoints.push({...wp}));
        } else {
            // From RouteDto: translate WaypointList to Waypoint[]
            const wpl = obj.waypoints;
            if (wpl.lat.length === wpl.lng.length) {
                for (let i=0; i<wpl.lat.length; i++)
                    this.waypoints.push(new Waypoint(wpl.lat[i], wpl.lng[i]));
            }
        }
    }

    // create a copy that can be saved with a new owner
    copy() {
        const route = new Route(this);
        route.id = null;
        route.ownerId = null;
        route.isPublic = false;
        return route;
    }

    // ************************************************************************

    getWaypoint(index: number) {
        return this.waypoints[index];
    }

    getWaypointIndexById(id: number) {
        return this.waypoints.findIndex(wp => wp.id === id);
    }

    setStart(waypoint: Waypoint) {
        if (!this._checkWaypointLimit()) return;
        if (this.waypoints.length <= 0)
            this.waypoints.push(waypoint);
        else
            this.waypoints[0] = waypoint;
    }

    setTarget(waypoint: Waypoint) {
        if (!this._checkWaypointLimit()) return;
        const length = this.waypoints.length;
        if (length <= 0)
            console.error("[ROUTE] cannot set target, if start is not defined");
        else if (length === 1)
            this.waypoints.push(waypoint);
        else
            this.waypoints[length-1] = waypoint;
    }

    addWaypoint(waypoint: Waypoint) {
        if (!this._checkWaypointLimit()) return;
        const wps = this.waypoints;
        if (wps.length < 2) {
            this.waypoints.push(waypoint);
            return;
        }
        let minDist = 1000000;
        let bestIndex = 1;
        const maxIndex = wps.length - 1;
        for (let i=1; i<=maxIndex; i++) {
            const d0 = Waypoint.getDistance(waypoint, wps[i-1]);
            const d1 = Waypoint.getDistance(waypoint, wps[i]);
            const dist = d0 + d1;
            if (dist < minDist) {
                minDist = dist;
                bestIndex = i;
            }
        }
        this.waypoints.splice(bestIndex, 0, waypoint);
    }

    _checkWaypointLimit() {
        if (this.waypoints.length >= Route.MAX_WAYPOINTS) {
            showDialogRouteMaxpoints();
            return false;
        }
        return true;
    }

    moveWaypointPos(index: number, toLat: number, toLng: number) {
        const wp = this.waypoints[index];
        wp.lat = toLat;
        wp.lng = toLng;
    }

    removeWaypoint(index: number) {
        if (this.waypoints.length > 0)
            this.waypoints.splice(index, 1);
    }

    moveWaypointIndex(oldIndex: number, newIndex: number) {
        const length = this.waypoints.length;
        if ((oldIndex < 0) || (oldIndex >= length)) {
            console.warn("Route.moveWaypointIndex: old index out of range", oldIndex, length);
            return;
        }
        if ((newIndex < 0) || (newIndex >= length)) {
            console.warn("Route.moveWaypointIndex: new index out of range", newIndex, length);
            return;
        }
        const element = this.waypoints[oldIndex];
        this.waypoints.splice(oldIndex, 1);
        this.waypoints.splice(newIndex, 0, element);
    }

    moveWaypointUp(index: number) {
        if (index < this.waypoints.length-1)
            this.moveWaypointIndex(index, index + 1);
    }

    moveWaypointDown(index: number) {
        if (index > 0)
           this.moveWaypointIndex(index, index - 1);
    }

    reverseDirection() {
        this.waypoints.reverse();
    }

    // ************************************************************************

    getRouteBBox() {
        const maxLat = Math.max.apply(
            Math,
            this.waypoints.map((wp) => wp.lat)
        );
        const maxLng = Math.max.apply(
            Math,
            this.waypoints.map((wp) => wp.lng)
        );
        const minLat = Math.min.apply(
            Math,
            this.waypoints.map((wp) => wp.lat)
        );
        const minLng = Math.min.apply(
            Math,
            this.waypoints.map((wp) => wp.lng)
        );
        return [
            [minLat, minLng],
            [maxLat, maxLng],
        ] as LatLngBoundsExpression;
    }

    // ************************************************************************

    static updateRoute(update: (route: Route) => void) {
        Route.editActiveRoute().then(result => {
            if (result) {
                useRouteStore.getState().apply((route) => update(route));
            }
        })
    };

    static async shareRoute(routeId: string) {
        const route = await RouteDb.getById(routeId);
        route.isPublic = true;
        await RouteDb.save(route);
        useRouteStore.getState().updateList();
    }

    static async unshareRoute(routeId: string) {
        const route = await RouteDb.getById(routeId);
        route.isPublic = false;
        await RouteDb.save(route);
        useRouteStore.getState().updateList();
    }

    static async editActiveRoute() {
        const state = useRouteStore.getState();
        const route = state.activeObject;
        const savedRoute = state.saved;
        if (!route) return false;
        if (savedRoute) return true;
        const userId = useUserStore.getState().data?.id;
        if (!route.isPublic || (route.ownerId === userId)) {
            Route._startEditing();
            return true;
        } else {
            return await showDialogRouteCopy();
        }
    }

    static _startEditing() {
        const state = useRouteStore.getState();
        state.backupAndEdit(new Route(state.activeObject!));
    }

    static async copyActiveRoute() {
        const activeRoute = useRouteStore.getState().activeObject;
        if (!activeRoute) return;
        const route = activeRoute.copy();
        const state = useRouteStore.getState();
        state.set(route);
        const newRoute = new Route(route);
        state.backupAndEdit(newRoute);
        await RouteDb.save(newRoute);
    }
}
