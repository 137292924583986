
import React, { useState } from "react";

import styled from "styled-components";

// ****************************************************************************

export function Accordion(props: React.PropsWithChildren<{}>)
{
    const { children } = props;
    return <div>{children}</div>;
}

// ****************************************************************************

const HeaderDiv = styled.div<{$bgColor: string}>`
    background-color: ${p => p.$bgColor};
    color: #fff;
    padding: .7em 1em;
    margin: 0;
    cursor: pointer;
`;

const HeaderText = styled.div<{$isOpen: boolean}>`
    margin: 0;
    padding: 0;
    &::before {
        display: inline-block;
        content: '';
        height: .65em;
        width: .65em;
        margin-right: .8em;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(${p => p.$isOpen? "45deg": "-45deg"});
    }
`;

const Panel = styled.div`
    margin: 0;
    padding: .5em .8em;
    background-color: #eee;
    animation: fadein 0.35s ease-in;
    display: flex;
    flex-flow: column nowrap;
    gap: 1em;
    justify-content: space-between;
`;

// ****************************************************************************

type AccordionPanelProps = React.PropsWithChildren<{
    heading: string;
    initiallyOpen?: boolean;
    bgColor?: string;
}>;

export function AccordionPanel(props: AccordionPanelProps)
{
    const { heading, initiallyOpen, bgColor, children } = props;

    const [ isOpen, setIsOpen ] = useState(initiallyOpen || false);

    const headerColor = bgColor || "#2869af";

    const onToggleOpen = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        setIsOpen(!isOpen);
    };

    return (
        <>
            <HeaderDiv onClick={(ev) => onToggleOpen(ev)} $bgColor={headerColor}>
                <HeaderText $isOpen={isOpen}>{heading}</HeaderText>
            </HeaderDiv>
            { isOpen && <Panel>{children}</Panel> }
        </>
    );
}
