
import styled from "styled-components";
import useBrowserInfo from "./BrowserInfo";


const LayoutColumnsWrapper = styled.div<{$windowWidth: number}>`
    display: grid;
    grid-template-columns: ${p => (p.$windowWidth >= 500)? '1fr 1fr': '1fr'};
    column-gap: 4em;
    flex-grow: 1;
`;


export default function LayoutColumns(props: React.PropsWithChildren<{}>) {
    const browser = useBrowserInfo();
    return (
        <LayoutColumnsWrapper $windowWidth={browser.width}>
            {props.children}
        </LayoutColumnsWrapper>
    );
}
