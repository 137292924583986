
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { Rider, RiderCadenceList, RiderCadenceStrings, RiderFitnessList, RiderFitnessStrings } from "../../types/rider";
import useRiderStore, { RiderDb } from "../../Database/RiderCollection";
import { FormGroupRow, NumericInput, SaveAbortButtons, Select, TextInput } from "../GuiElements/forms";
import { ModalDialog } from "../GuiElements/modaldialog";

// ****************************************************************************

export function RiderSettingsForm()
{
    const rider = RiderDb.getEditObject();

    const { register, watch, setValue, handleSubmit, formState: { errors }, } = useForm<Rider>({
        mode: "onChange",
        defaultValues: RiderDb.getEditObject()!,
        shouldUseNativeValidation: true,
    });

    const powerRef = useRef<HTMLSelectElement>(null);
    const cadenceRef = useRef<HTMLSelectElement>(null);

    const onSubmit = (rider: Rider) => {
        useRiderStore.getState().saveAndSelect(rider);
        RiderDb.stopEditing();
    };

    const onAbort = () => {
        RiderDb.stopEditing();
    };

    useEffect(() => {
        const watchSubsrc = watch((value, { name }) => {
            if (!value) return;
            if ((name === 'powerW') && powerRef.current && value.powerW)
                powerRef.current.value = Rider.getFitnessLevel(value.powerW);
            else if ((name === 'cadenceRpm') && cadenceRef.current && value.cadenceRpm)
                cadenceRef.current.value = Rider.getCadenceLevel(value.cadenceRpm);
        });
        return () => watchSubsrc.unsubscribe();
    }, [watch]);

    if (!rider) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput error={errors.name?.message}
                label="Name"
                {...register("name", { required: "Bitte gib einen Namen an." })}
            />
            <NumericInput error={errors.heightCm?.message}
                label="Körpergröße" unit="cm" step={1}
                {...register("heightCm", {
                    required: "Bitte gib Deine Körpergröße an.",
                    min: { value: 50, message: "Die Körpergröße muss größer als 50 cm sein." },
                    max: { value: 250, message: "Das Körpergröße muss kleiner als 250 cm sein." },
                    valueAsNumber: true,
                })}
            />
            <NumericInput error={errors.weightKg?.message}
                label="Gewicht" unit="kg" step={1}
                {...register("weightKg", {
                    required: "Bitte gib Dein Gewicht an.",
                    min: { value: 50, message: "Das Gewicht muss größer als 50 kg sein." },
                    max: { value: 150, message: "Das Gewicht muss kleiner als 150 kg sein." },
                    valueAsNumber: true,
                })}
            />

            <FormGroupRow label="Trittleistung" error={errors.powerW?.message}>
                <Select ref={powerRef}
                    defaultValue={Rider.getFitnessLevel(rider.powerW)}
                    options={Object.keys(RiderFitnessList).map((v) => ({ key: v, value: RiderFitnessStrings[v] }))}
                    onChange={(ev) => setValue("powerW", RiderFitnessList[ev.target.value])}
                />
                <NumericInput
                    unit="W" step={5}
                    {...register("powerW", {
                        required: "Bitte gib Deine Trittleistung an.",
                        min: { value: 50, message: "Die Trittleistung muss größer als 50 W sein." },
                        max: { value: 400, message: "Die Trittleistung muss kleiner als 400 W sein." },
                        valueAsNumber: true,
                    })}
                />
            </FormGroupRow>

            <FormGroupRow label="Trittfrequenz">
                <Select ref={cadenceRef}
                    defaultValue={Rider.getCadenceLevel(rider.cadenceRpm)}
                    options={Object.keys(RiderCadenceList).map((v) => ({ key: v, value: RiderCadenceStrings[v] }))}
                    onChange={(ev) => setValue("cadenceRpm", RiderCadenceList[ev.target.value])}
                />
                <NumericInput
                    unit="U/min" step={1}
                    {...register("cadenceRpm", {
                        required: "Bitte gib Deine Trittfrequenz an.",
                        min: { value: 10, message: "Die Trittfrequenz muss größer als 10 U/min sein." },
                        max: { value: 120, message: "Die Trittfrequenz muss kleiner als 120 U/min sein." },
                        valueAsNumber: true,
                    })}
                />
            </FormGroupRow>

            <SaveAbortButtons onAbort={onAbort} />
        </form>
    );
};

// ****************************************************************************

export default function RiderSettingsDialog()
{
    const isEditing = useRiderStore((state) => state.isEditing);
    if (!isEditing) return null;
    return (
        <ModalDialog title="Fahrer Einstellungen">
            <RiderSettingsForm/>
        </ModalDialog>
    );
}
