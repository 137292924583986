
import { WebSocketEndpoint } from "../config";
import { getSessionToken } from "../stores/Tokens";

// FIXME: move to config
const gReconnectTimeout = 1000;
const gKeepAliveTimeout = 25000;


interface IMessageHandler {
    (data: string): void;
}


class WebSocketClient
{
    private url: string;
    private socket: WebSocket | null = null;
    private isConnected: boolean = false;
    private queue: string[] = [];
    private keepAliveTimer: NodeJS.Timer | null = null;

    private handlers: Record<string,IMessageHandler> = { };

    // URL must be "wss://..."
    constructor(url: string) {
        this.url = url;
    }

    connect() {
        // allow access to this from the handlers below
        const self = this;

        console.log("[WS] connecting");
        this.socket = new WebSocket(this.url);

        this.socket.onopen = (event) => {
            console.log("[WS] connected");
            self.isConnected = true;
            if (self.queue.length > 0) {
                const queue = self.queue;
                self.queue = [];
                console.log("[WS] sending " + queue.length + " queued messages")
                queue.forEach((msg) => self.socket!.send(msg));
            }
            if (this.keepAliveTimer) clearInterval(this.keepAliveTimer);
            this.keepAliveTimer = setInterval(() => self.sendKeepAlive(), gKeepAliveTimeout);
        };

        this.socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type in self.handlers)
                self.handlers[message.type](message.data);
            else
                console.log("[WS] ignoring unkown message type " + message.type);
        };

        this.socket.onclose = function(e) {
            self.isConnected = false;
            console.warn('WebSocket was closed, reconnecting.');
            self.socket = null;
            if (self.keepAliveTimer) {
                clearInterval(self.keepAliveTimer);
                self.keepAliveTimer = null;
            }
            if (gReconnectTimeout)
                setTimeout(() => self.connect(), gReconnectTimeout);
        };

        this.socket.onerror = function(err) {
            self.isConnected = false;
            console.warn('WebSocket error, closing.');
            self.socket?.close();
            self.socket = null;
            if (self.keepAliveTimer) {
                clearInterval(self.keepAliveTimer);
                self.keepAliveTimer = null;
            }
        };
    }

    send(type: string, data: any) {
        if (typeof data !== 'string')
            data = JSON.stringify(data);
        const message = {
            type: type,
            auth: getSessionToken(),
            data: data,
        }
        const messageString = JSON.stringify(message);
        if (this.isConnected)
            this.socket!.send(messageString);
        else
            this.queue.push(messageString);
    }

    sendKeepAlive() {
        if (this.isConnected)
            this.socket!.send(JSON.stringify({type: "keep_alive"}));
    }

    addHandler(msgType: string, handler: IMessageHandler) {
        this.handlers[msgType] = handler;
    }
}

const gWebSocket = new WebSocketClient(WebSocketEndpoint);
gWebSocket.connect();

export default gWebSocket;
