
import { GridContainer, GridPair } from "../../GridComponents";

import useRdgStore, { getPredictionResults } from "../../stores/Rdg";
import useRouteStore from "../../db/routedb";

import { IconTextBar, IconTextPair } from "../GuiElements/IconTextBar";


export default function ResultDisplay() {
    const route = useRouteStore((state) => state.activeObject);
    const routeInfo = useRdgStore((state) => state.routeInfo);
    const predictionResults = getPredictionResults(useRdgStore());

    if (route && (route.waypoints.length < 2)) {
        return <p>
            Für die Auswertung müssen zumindest Start und Ziel angegeben werden.
            Klicke dazu in die Karte oder wähle oben unter "Meine Routen" eine gespeicherte Route.
        </p>;
    }

    const [ distance, uphill, downhill ] = (!routeInfo)? [ "", "", "" ]: [
        `${Math.round(routeInfo.distanceM / 1000)} km`,
        `${Math.round(routeInfo.uphillM)} m`,
        `${Math.round(routeInfo.downhillM)} m`,
    ];

    const [ rideTimeTotal, batteryPercent, batteryOk ] = (!predictionResults)? [ "", "", true ]: [
        predictionResults.rideTimeTotal,
        `${predictionResults.batteryConsumptionPercent} %`,
        (predictionResults.batteryConsumptionPercent < 95),
    ];

    return (
        <>
            { routeInfo &&
                <IconTextBar>
                    <IconTextPair icon="&#11020;" text={distance} tooltip="Gesamtstrecke" />
                    <IconTextPair icon="&#11016;" text={uphill} tooltip="Bergauf" />
                    <IconTextPair icon="&#11018;" text={downhill} tooltip="Bergab" />
                </IconTextBar>
            }
            { predictionResults &&
                <GridContainer>
                    <GridPair left="Fahrtzeit" right={rideTimeTotal} />
                    <GridPair left="Akkuverbrauch" right={batteryPercent} bold={!batteryOk} />
                </GridContainer>
            }
            { !predictionResults && <p>Auswertung läuft ...</p> }
        </>
    );
};
