
import React from "react";

import useRouteStore from "../../db/routedb";
import useModalDialog from "../../stores/modalstore";
import { Route } from "../../types/route";
import { showDialogConfirmDelete } from "../dialogs/edit-dialogs";
import { showDialogRouteShare, showDialogRouteUnShare } from "../dialogs/route-dialogs";
import { ButtonDelete, ButtonShare, ButtonUnShare } from '../GuiElements/IconButtons';

// ****************************************************************************

function openMap(ev: React.MouseEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    let id = ev.currentTarget.id;
    if (id.startsWith("route-")) {
        id = id.substring(6)
        useRouteStore.getState().setById(id);
    }
    useModalDialog.getState().close();
};

// ****************************************************************************

function deleteRoute(id: string, name: string) {
    showDialogConfirmDelete(
        <span>die Route <b>{name}</b></span>,
        () => useRouteStore.getState().deleteById(id),
    );
};

// ****************************************************************************

export default function RouteOverview(props: { route: Route, community: boolean }) {
    const { route, community } = props;

    const showUnshare = route.isPublic && !community;

    return (
        <div className="route-overview" id={"route-" + route.id} onClick={ev => openMap(ev)}>
            <h2>{route.name}</h2>
            { showUnshare && <ButtonUnShare onClick={() => showDialogRouteUnShare(route.id!, route.name)}/> }
            { !route.isPublic && <ButtonShare onClick={() => showDialogRouteShare(route.id!, route.name)}/> }
            { !route.isPublic && <ButtonDelete onClick={() => deleteRoute(route.id!, route.name)}/> }
        </div>
    )
}
