
import styled from "styled-components";

import { ButtonInfo } from "./IconButtons";


const InfoBoxStyle = styled.div`
    display: flex;
    gap: .6em;
    align-items: center;
    .main { flex-grow: 1 }
    .info .popup {
        display: none;
        position: absolute;
        max-width: 300px;
        border: solid 2px #000;
        border-radius: 5px;
        padding: 5px;
        color: ${p => p.theme.cols.textHigh2};
        background-color: ${p => p.theme.cols.high2};
        z-index: 1000;
        transform: translate(0, -100%);
    }
    .info:hover .popup { display: block; }
`;


export default function InfoBox(props: React.PropsWithChildren<{text: string}>) {

    return (
        <InfoBoxStyle>
            <div className="main">{props.children}</div>
            <div className="info">
                <div className="popup">{props.text}</div>
                <ButtonInfo/>
            </div>

        </InfoBoxStyle>
    );
}
