
import useRdgStore from "../../stores/Rdg";

import { RiderEffort } from "../../RiderEffort";
import useMotorStore from "../../Database/MotorCollection";
import { NumericInput, Select } from "../GuiElements/forms";
import InfoBox from "../GuiElements/infobox";

// ****************************************************************************

const infoAdditionalWeight = "Alles, was außer Fahrer und E-Bike noch transportiert wird, zum Beispiel eine Fahrradtasche."

// ****************************************************************************

export default function PredictionSettings() {
    const desiredRiderEffortSelection = useRdgStore((state) => state.desiredRiderEffortSelection);
    const activeMotor = useMotorStore((state) => state.activeObject);
    const supportModeIndex = useRdgStore((state) => state.supportModeIndex);
    const additionalWeight = useRdgStore((state) => state.additionalWeight);

    return (
        <div>
            <Select
                label="Unterstützungprofil" name="support-profile"
                value={supportModeIndex.toString()}
                options={[{ key: -1, value: "Mixed" }].concat(activeMotor? activeMotor.supportNames.map((v,i) => ({ key: i, value: v })): [])}
                onChange={(ev) => useRdgStore.getState().setSupportModeIndex(parseInt(ev.target.value))}
            />
            <Select
                label="Gewünschte Anstrengung" name="effort"
                value={desiredRiderEffortSelection}
                options={Object.values(RiderEffort).filter((v) => typeof v === "string").map((v,i) => ({ key: i, value: v }))}
                onChange={(ev) => useRdgStore.getState().setDesiredRiderEffortSelection(parseInt(ev.target.value))}
            />
            <InfoBox text={infoAdditionalWeight}>
                <NumericInput
                    label="Zusätzliches Gewicht" name="additional-weight"
                    value={additionalWeight}
                    unit="kg" min={0} max={50}
                    onChange={(ev) => useRdgStore.getState().setAdditionalWeight(parseInt(ev.target.value))}
                />
            </InfoBox>
        </div>
    );
};
