
import { useForm } from 'react-hook-form';

import { UserRegistrationData } from "../../types/user";
import useUserStore from "../../stores/userstore";
import { Checkbox, PasswordInput, SubmitButton, TextInput } from '../GuiElements/forms';
import { getLoginErrorMessage } from "./UserLoginError";


const onRegistration = (registerData: UserRegistrationData) => {
    useUserStore.getState().register(registerData);
}


export default function UserRegistrationForm() {
    const registrationError = useUserStore((store) => store.registrationError);

    const { register, handleSubmit, formState: { errors }, } = useForm<UserRegistrationData>({
        mode: "onChange",
        shouldUseNativeValidation: true,
    });

    return (
        <form onSubmit={handleSubmit(onRegistration)}>
            <h2>Registrieren</h2>
            { registrationError && getLoginErrorMessage(registrationError) }
            <TextInput error={errors.userName?.message}
                label="Benutzername"
                {...register("userName", { required: "Bitte gib einen Benutzernamen ein." })}
            />
            <TextInput error={errors.fullName?.message}
                label="Vollständiger Name"
                {...register("fullName", { required: "Bitte gib Deinen vollständigen Namen ein." })}
            />
            <TextInput error={errors.email?.message}
                label="E-Mail-Adresse"
                {...register("email", { required: "Bitte gib Deine E-Mail-Adresse ein." })}
            />
            <PasswordInput error={errors.password?.message}
                label="Passwort"
                {...register("password", { required: "Bitte lege ein Passwort fest." })}
            />
            <PasswordInput error={errors.password2?.message}
                label="Wiederholung des Passworts"
                {...register("password2", { required: "Bitte gib Dein Passwort nochmal ein." })}
            />
            <Checkbox error={errors.agreeResearch?.message}
                label="Ich willige ein, dass meine gespeicherten Daten anonym zu Forschungszwecken ausgewertet werden."
                {...register("agreeResearch")}
            />
            <Checkbox error={errors.remember?.message}
                label="Anmeldung speichern"
                {...register("remember")}
            />
            <SubmitButton/>
            {/* Mit der Anmeldung stimmst du unseren Nutzungsbedingungen zu. */}
        </form>
    );
}
