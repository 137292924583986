
import { DbObject } from "../db/collection";

// ****************************************************************************

export class Rider extends DbObject
{
    weightKg: number = 80;
    heightCm: number = 180;
    cadenceRpm: number = 0;
    powerW: number = 0;

    constructor(obj?: Rider) {
        super(obj);
        if (obj) {
            this.weightKg = obj.weightKg;
            this.heightCm = obj.heightCm;
            this.cadenceRpm = obj.cadenceRpm;
            this.powerW = obj.powerW;
        } else {
            Rider.setFitnessLevel(this, "normal");
            Rider.setCadenceLevel(this, "normal");
        }
    }

    static getDefault() {
        const rider = new Rider();
        rider.name = 'Standard Fahrer';
        return rider;
    }

    static getNew() {
        const rider = new Rider();
        rider.name = 'Neuer Fahrer';
        return rider;
    }

    // FIXME: For some reasons member functions don't work in the form.
    // Static functions do work.

    static setFitnessLevel(self: Rider, fitnessLevel: string) {
        const power = RiderFitnessList[fitnessLevel];
        if (power > 0) self.powerW = power;
    }
    static getFitnessLevel(powerW: number) {
        const level = Object.keys(RiderFitnessList).find((key) => (RiderFitnessList[key] === powerW));
        return (level)? level: "manual";
    }
    static getFitnessRating(self: Rider) {
        let dist = 500;
        let level = '';
        Object.keys(RiderFitnessList).forEach((key) => {
            const power = RiderFitnessList[key];
            if (power > 0) {
                const d = Math.abs(self.powerW - power);
                if (d < dist) {
                    dist = d;
                    level = key;
                }
            }
        });
        return level;
    }

    static setCadenceLevel(self: Rider, cadenceLevel: string) {
        const cadence = RiderCadenceList[cadenceLevel];
        if (cadence > 0) self.cadenceRpm = cadence;

    }
    static getCadenceLevel(cadenceRpm: number) {
        const level = Object.keys(RiderCadenceList).find((key) => (RiderCadenceList[key] === cadenceRpm));
        return (level)? level: "manual";
    }
}

// ****************************************************************************

export const RiderCadenceList: Record<string, number> = {
    veryslow: 50,
    slow: 60,
    normal: 70,
    fast: 80,
    veryfast: 90,
    manual: -1,
};

export const RiderCadenceStrings: Record<string, string> = {
    veryslow:  "Sehr Langsam",
    slow: "Langsam",
    normal: "Normal",
    fast: "Schnell",
    veryfast: "Sehr Schnell",
    manual: "Manuelle Eingabe",
};

// ****************************************************************************

export const RiderFitnessList: Record<string, number> = {
    untrained: 50,
    normal: 100,
    athletic: 200,
    professional: 300,
    manual: -1,
};

export const RiderFitnessStrings: Record<string, string> = {
    untrained: "Untrainiert",
    normal:  "Normal",
    athletic: "Sportlich",
    professional: "Profi",
    manual: "Manuelle Eingabe",
};
