
import { useMemo, useRef } from "react";
import { renderToString } from "react-dom/server";
import { Marker } from "react-leaflet";
import L, { LeafletMouseEvent } from "leaflet";

import useRouteStore from "../../db/routedb";
import useGui from "../../stores/guistore";
import { Route, Waypoint } from "../../types/route";

import "./MapMarker.css";

// ****************************************************************************

export function MapMarkerIcon(props: { index: number, isLast: boolean, isActive: boolean }) {
    let className = 'map-marker';
    if (props.isActive) className += ' active';
    let label = ''
    if (props.index === 0) {
        label = 'Start';
        className += ' map-marker-start';
    } else if (props.isLast) {
        label = 'Ziel';
        className += ' map-marker-end';
    } else {
        let i = props.index - 1, offset = 65;
        do {
            label = String.fromCharCode(i % 26 + offset) + label
            i = Math.floor(i / 26);
            offset = 64;  // prefix 0 -> ' ', 1 -> A, ...
        } while (i > 0);
    }
    return (
        <div className={className}>{label}</div>
    );
}

// ****************************************************************************

interface WaypointProps {
    key: number;
    index: number;
    waypoint: Waypoint;
}

export function MapWaypointMarker(props: WaypointProps) {
    const { index, waypoint } = props;

    const route = useRouteStore.getState().activeObject;
    const selectedWaypoint = useGui(state => state.selectedWaypoint);

    const isLast = route? (index >= route.waypoints.length-1): false;
    const isActive = (index === selectedWaypoint);

    const markerRef = useRef<L.Marker>(null);

    const icon = useMemo(
        () => L.divIcon({
            className: 'my-leaflet-marker',
            iconSize: undefined,
            html: renderToString(<MapMarkerIcon index={index} isActive={isActive} isLast={isLast} />),
        }),
        [index, isActive, isLast]
    );

    const eventHandlers = useMemo(
        () => ({
            click(ev: LeafletMouseEvent) {
                L.DomEvent.stop(ev);
                useGui.getState().setSelectedWaypoint(index);
            },
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    const latLng = marker.getLatLng();
                    Route.updateRoute(route => {
                        route.moveWaypointPos(index, latLng.lat, latLng.lng);
                    });
                }
            },
        }),
        [index]
    );

    return (
        <Marker
            position={waypoint} icon={icon}
            draggable={true} ref={markerRef}
            eventHandlers={eventHandlers}
        />
    );
}
