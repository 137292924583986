
import { PlotColor, ResultPlotColor } from "./Components/Results/ResultPlotConfig";

const PlotColors = (props: { plotColors: number[] }) => {
    const lastIndex = props.plotColors.length;
    if (lastIndex === 0) return null;

    return (
        <>
            {props.plotColors.map((value, index) => {
                const percentage = (index * 100) / lastIndex;
                return <stop key={"plotColor" + index} offset={`${percentage}%`} stopColor={ResultPlotColor[value as PlotColor]} />;
            })}
        </>
    );
};

export default PlotColors;
