
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, TooltipProps } from "recharts";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";
import styled from "styled-components";

import PlotColors from "../../PlotColors";
import { PlotColor, ResultPlotColor, ResultPlotData } from "../Results/ResultPlotConfig";
import { getRoutePopupData, RoutePopup } from "../Routes/RoutePopup";

// ****************************************************************************

const DataplotTooltip = styled.div`
    border: solid 2px #2869af;
    background-color: #eee;
    border-radius: 4px;
`;

type Props = {
    data: ResultPlotData[];
    colors: number[];
    axes: string;
    rangeMin?: number;
    rangeMax?: number;
    thumbnail?: boolean;
    onClick?: CategoricalChartFunc;
}

export default function DataPlot(props: Props)
{
    const { data, colors, axes, rangeMin, rangeMax, thumbnail, onClick } = props;

    const xAxis = axes[0];
    const yAxis = axes[1];

    function renderTooltip(props: TooltipProps<number,string>) {
        const { active, payload } = props;
        if (!active || !payload || !payload.length)
            return null;

        const point: ResultPlotData = payload[0].payload;
        const plotIndex = point.i;
        const distance = point.d;
        const routeIndex = Math.round(50 * distance);

        return (
            <DataplotTooltip>
                <RoutePopup data={getRoutePopupData(routeIndex, plotIndex)} />
            </DataplotTooltip>
        );
    };

    const domainMin = (rangeMin != null)? rangeMin.toString(): "dataMin";
    const domainMax = (rangeMax != null)? rangeMax.toString(): "auto";
    const domain = [ domainMin, domainMax ];

    let stroke, defs;
    if (colors.length === 1) {
        stroke = ResultPlotColor[colors[0] as PlotColor];
    } else {
        stroke = "url(#gradient)";
        defs = (<defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="100%" y2="0">
                    <PlotColors plotColors={colors} />
                </linearGradient>
            </defs>);
    }

    return (
        <ResponsiveContainer width="100%">
            <LineChart data={data} margin={{ left: 0, right: 0 }} onClick={onClick}>
                {defs}
                <Line type="monotone" dataKey={yAxis} stroke={stroke} dot={false} strokeWidth={3} isAnimationActive={false} />
                <CartesianGrid stroke="#000" strokeDasharray="7 7" />
                <Tooltip content={renderTooltip} />
                {
                    (thumbnail)? (
                        <>
                            <XAxis tick={false} height={0} dataKey={axes[0]} stroke="black" />
                            <YAxis tick={false} width={0} type="number" stroke="black" domain={domain} />
                        </>
                    ):(
                        <>
                            <XAxis height={25} dataKey={xAxis} stroke="black" interval={Math.round(data.length / 9)} />
                            <YAxis width={40} type="number" stroke="black" domain={domain} tickCount={10} />
                        </>
                    )
                }
            </LineChart>
        </ResponsiveContainer>
    );
};
