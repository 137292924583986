
import { createStore } from "./zustand";
import { MapServiceDefault } from "../types/mapservice";
import { Coordinate } from "../types/route";

type State = {
    mapService: string;
    mapLocation: Coordinate | null;
    setMapService: (ms: string) => void;
    setMapLocation: (latlng: Coordinate|null) => void;
};

const useMapStore = createStore<State>((set, get) => ({
    mapService: MapServiceDefault,
    mapLocation: null,

    setMapService: (ms) => {
        set((state) => {
            state.mapService = ms;
        });
    },

    setMapLocation: (latlng) => {
        set((state) => {
            state.mapLocation = latlng;
        });
    },
}));

export default useMapStore;
