
export function storeLoginToken(token: string) {
    if (token.length > 0)
        localStorage.setItem('login-token', token);
    else
        localStorage.removeItem('login-token');
}

export function getLoginToken(): string|null {
    return localStorage.getItem('login-token');
}


export function storeSessionToken(token: string) {
    if (token.length > 0)
        sessionStorage.setItem('session-token', token);
    else
        sessionStorage.removeItem('session-token');
}

export function getSessionToken(): string|null {
    return sessionStorage.getItem('session-token');
}
