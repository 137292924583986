
import { useEffect } from "react";

import { Ebike } from "../types/ebike";
import { Rider } from "../types/rider";
import { RouteDto } from "../types/route";

import gWebSocket from "../db/websocket";
import useEbikeStore from "../Database/EbikeCollection";
import useModalDialog from "../stores/modalstore";
import useRdgStore, { getPredictionResults } from "../stores/Rdg";
import useRiderStore from "../Database/RiderCollection";
import useRouteStore from "../db/routedb";

import { ResultPlotData } from "../Components/Results/ResultPlotConfig";
import { CloseButton } from "../Components/GuiElements/modaldialog";

// ****************************************************************************

// FIXME: move these to directory "Types"

export interface PlotDataDto {
    distance: number[];
    altitude: number[];
    slope: number[];
}

export interface PredictionResults {
    rideTimeTotal: string;
    batteryConsumptionPercent: number;
    batteryAtArrivalInPercent: number;
    rideTime: number[];
}

export interface MixedPredictionResults extends PredictionResults {
    modeIndexRle: number[][];
}

// ****************************************************************************

// Delay requests by some milliseconds, so they can be cancelled, if a new
// request is generated.
const gRequestDelay = 200;

// There must be only one outstanding request. Results for earlier requests
// are discarded.
let gCurRequest: ComputeRequestDto | null = null;
let gRequestTimer: ReturnType<typeof setTimeout> | null = null;

class ComputeRequestDto {
    requestId: number;
    route: RouteDto;
    ebike?: Ebike;
    rider?: Rider;
    additionalWeight?: number;

    constructor(route: RouteDto, ebike: Ebike, rider: Rider, additionalWeight: number) {
        ComputeRequestDto.sNextId = (ComputeRequestDto.sNextId+1) & 0xffff;
        this.requestId = ComputeRequestDto.sNextId;
        this.route = route;
        this.ebike = ebike;
        this.rider = rider;
        this.additionalWeight = additionalWeight;
    }

    private static sNextId = 1;
}

export interface RoutingResultDto {
    lat: number[];
    lng: number[];
    distanceM: number;
    uphillM: number;
    downhillM: number;
    distance: number[];
    altitude: number[];
    slope: number[];
};

export interface PredictionResultDto {
    predictionResults: PredictionResults[];
    mixedPredictionResults: MixedPredictionResults[];
};

// ****************************************************************************

const gErrorStrings: {[key: string]: string} = {
    routing_failed: 'Die Berechnung der Route ist fehlgeschlagen.',
    prediction_failed: 'Die Berechnung des Akkuverbrauchs ist fehlgeschlagen.',
    invalid_ebike: 'Die Beschreibung des E-Bikes enthält ungültige Werte.',
    invalid_rider: 'Die Beschreibung des Fahrers enthält ungültige Werte.',
    invalid_route: 'Die Routen-Beschreibung ist ungültig.',
    default_error: 'Unbekannter Fehler.',
};

export default function ComputeResults()
{
    // ************************************************************************
    // update plot data on new data or on support level changed
    // FIXME: maybe move to map editor with useMemo()

    const routeCoors = useRdgStore((state) => state.routeCoors);
    const routePlotData = useRdgStore((state) => state.routePlotData);

    const predictionResults = getPredictionResults(useRdgStore());

    useEffect(() => {
        console.log("Updating plot data.");

        const plotData: ResultPlotData[] = [];

        if ((routePlotData != null) && (routeCoors != null)) {
            const size = routePlotData.distance.length;
            for (let i = 0; i < size; i++) {
                // route index is distance / 0.02
                const distance = routePlotData.distance[i];
                let routeIndex = Math.round(distance / 0.02);
                if (routeIndex >= routeCoors.length)
                    routeIndex = routeCoors.length - 1;
                const latlng = routeCoors[routeIndex];
                const p: ResultPlotData = {
                    i: i,
                    c: latlng,
                    d: distance,
                    a: routePlotData.altitude[i],
                    s: routePlotData.slope[i],
                };
                if (predictionResults != null) {
                    p.t = predictionResults.rideTime[i];
                }
                plotData.push(p);
            }
        }
        useRdgStore.getState().setPlotData(plotData);
    },
    [ routeCoors, routePlotData, predictionResults ]);


    // ************************************************************************
    // call backend for prediction

    const route = useRouteStore((state) => state.activeObject);
    const activeEbike = useEbikeStore((state) => state.activeObject);
    const activeRider = useRiderStore((state) => state.activeObject);
    const additionalWeight = useRdgStore((state) => state.additionalWeight);

    const onResultRoute = (data: string) => {
        const results = JSON.parse(data) as RoutingResultDto;
        useRdgStore.getState().setRoutingResults(results);
    };

    const onResultPrediction = (data: string) => {
        const results = JSON.parse(data) as PredictionResultDto;
        useRdgStore.getState().setPredictionResults(results);
    };

    const onComputeError = (data: string) => {
        let message;
        if (data in gErrorStrings) {
            if (data in gErrorStrings)
                message = gErrorStrings[data];
            else
                message = gErrorStrings['default_error'];
        }
        useModalDialog.getState().show(
            <p>Bei der Auswertung ist ein Fehler aufgetreten:<br/>{message}</p>,
            [ CloseButton ]);
    };

    useEffect(() => {
        gWebSocket.addHandler("result_route", onResultRoute);
        gWebSocket.addHandler("result_prediction", onResultPrediction);
        gWebSocket.addHandler("compute_error", onComputeError);
    },
    []);

    useEffect(() => {
        useRdgStore.getState().setRoutingResults(null);

        if (!route) return;

        // FIXME: should work now by handling motor properly?
        // avoid bad requests happening, when changing active ebike motor
        // works again after saving the ebike
        // if (activeEbike.motor !== activeMotor.id)
        //     return;

        if (route.waypoints.length < 2) {
            console.log("Aborting route computations: not enough waypoints.");
            return;
        }

        if (gRequestTimer) {
            if (gCurRequest) {
                console.log(`Cancelling compute request #${gCurRequest!.requestId}`);
                gCurRequest = null;
            } else {
                console.warn("Cancelling null compute request");
            }
            clearTimeout(gRequestTimer);
            gRequestTimer = null;
        }

        const routeDto = new RouteDto(route);
        const ebike = activeEbike? activeEbike: Ebike.getNew();
        const rider = activeRider? activeRider: Rider.getNew();
        gCurRequest = new ComputeRequestDto(routeDto, ebike, rider, additionalWeight);
        console.log(`Queuing compute request #${gCurRequest.requestId}`);

        gRequestTimer = setTimeout(() => {
            gRequestTimer = null;
            if (!gCurRequest) {
                console.warn("Request timer fired without pending compute request.");
                return;
            }
            console.log(`Sending compute request #${gCurRequest.requestId}`);
            // postRequest2<ComputeRequestDto, ComputeResultDto>("/rdg", gCurRequest)
            // .then((results) => {
            //     if (gCurRequest && (results.requestId === gCurRequest.requestId)) {
            //         gCurRequest = null;
            //         console.log(`Using results from compute request #${results.requestId}`);
            //         setResults(results);
            //     } else {
            //         console.warn(`Discarding obsolete data from compute request #${results.requestId}`);
            //     }
            // })
            // .catch((e) => console.log(e));
            gWebSocket.send('compute_route', JSON.stringify(gCurRequest));
        },
        gRequestDelay);

    }, [route, activeEbike, activeRider, additionalWeight ]);

    return null;
}
