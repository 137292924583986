
export interface UserData {
    id: string;
    userName: string;
    fullName: string;
    email: string;
    agreedResearch: boolean;
    loginToken: string;
    sessionToken: string;
}

export class UserLoginData {
    userName: string = '';
    password: string = '';
    remember: boolean = false;
}

export class UserRegistrationData {
    userName: string = '';
    fullName: string = '';
    email: string = '';
    password: string = '';
    password2: string = '';
    agreeResearch: boolean = false;
    remember: boolean = false;
}

export class UserNewPasswordDto {
    token: string = '';
    password: string = '';
    password2: string = '';
}

export class UserChangePasswordDto {
    currentPassword: string = '';
    password: string = '';
    password2: string = '';
}

export class UserAccountDeleteDto {
    password: string = '';
    deletePublic: boolean = false;
}

export interface UserMessageDto {
    message: string;
}

export function isDemoUser(user: UserData) {
    return (user.userName === 'demo');
}
