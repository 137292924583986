
import { Draft } from "immer/dist/types/types-external";
import { immerable } from "immer";
import { StateCreator } from "zustand";

import { deleteRequest, getRequest, postRequest, putRequest } from "../db/webapi";

// ****************************************************************************

export class DbObject {
    [immerable] = true;

    id: string | null;
    ownerId: string | null;
    isPublic: boolean;
    name: string;

    constructor(obj?: DbObject) {
        this.id = obj?.id ?? null;
        this.ownerId = obj?.ownerId ?? null;
        this.isPublic = obj?.isPublic ?? false;
        this.name = obj?.name ?? '';
    }
}

// ****************************************************************************

export interface DbCollectionState<ObjectType> {
    list: DbObject[];
    activeObject: ObjectType | null;
    saved: ObjectType | null;
    isEditing: boolean;
    allowEmpty: boolean;
    updateList: () => Promise<void>;
    set: (object: ObjectType) => void;
    unset: () => void;
    setById: (objectId: string | null) => void;
    delete: () => void;
    deleteById: (id: string) => void;
    saveAndSelect: (object: ObjectType) => void;
    apply: (op: (object: Draft<ObjectType>)=>void) => void;
    setIsEditing: (isEditing: boolean) => void;
    backupAndEdit: (object: ObjectType) => void;
    createAndEdit: (object: ObjectType) => void;
    saveEdit: () => void;
    cancelEdit: () => void;
    clear: () => void;
}

type DbCollectionStoreCreator<T> =
    StateCreator<DbCollectionState<T>, (fn: (draft: Draft<DbCollectionState<T>>) => void) => void>;

// ****************************************************************************

export default abstract class DbCollection<ObjectType extends DbObject, DtoType extends DbObject>
{
    private apiEndpoint: string;

    constructor(apiEndpoint: string) {
        this.apiEndpoint = apiEndpoint;
    }

    // ************************************************************************

    public storeConfig: DbCollectionStoreCreator<ObjectType> = ((set, get) => ({
        list: [],
        activeObject: null,
        saved: null,
        isEditing: false,
        allowEmpty: false,

        updateList: async () => {
            const list = await this.getList();
            list.sort((a, b) => (a.name<b.name? -1: a.name>b.name? 1: 0));
            set((state) => {
                state.list = list;
                if (!state.allowEmpty && !state.activeObject && (list.length > 0))
                    state.setById(list[0].id);
            });
        },

        set: (object) => {
            set((state) => {
                state.activeObject = object as Draft<ObjectType>;
            });
            this.hookPostSelect();
        },

        unset: () => {
            set((state) => {
                state.activeObject = null;
            });
        },

        setById: async (objectId) => {
            if (objectId == null) {
                get().unset();
                return;
            }
            const state = get();
            if (state.activeObject && (state.activeObject.id === objectId))
                return;
            const obj = await this.getById(objectId);
            get().set(obj);
        },

        delete: async () => {
            let activeObject = get().activeObject;
            if (activeObject)
                get().deleteById(activeObject.id!);
        },

        deleteById: async (id) => {
            let activeObject = get().activeObject;
            if (activeObject && (id === activeObject.id))
                get().unset();
            await this.delete(id);
            await get().updateList();
            const list = get().list;
            if (!get().allowEmpty && list && list.length > 0)
                get().setById(list[0].id);
        },

        apply: (op: (object: Draft<ObjectType>)=>void) => {
            set((state) => {
                if (state.activeObject != null)
                    op(state.activeObject);
            });
        },

        saveAndSelect: async (object) => {
            if (object == null) return;
            const state = get();
            if (object.id != null) {
                await this.save(object);
                state.set(object);
                state.updateList();
            } else {
                // FIXME: update list locally first?
                object = await this.save(object);
                await state.updateList();
                state.setById(object.id);
            }
        },

        setIsEditing: (isEditing) => {
            set((state) => {
                state.isEditing = isEditing;
            });
        },

        backupAndEdit: (object) => {
            set((state) => {
                state.isEditing = true;
                state.saved = object as Draft<ObjectType>;
            });
        },

        createAndEdit: (object) => {
            const draft = object as Draft<ObjectType>;
            set((state) => {
                state.isEditing = true;
                state.activeObject = draft;
                state.saved = null;
            });
        },

        saveEdit: async () => {
            const state = get();
            state.saveAndSelect(state.activeObject!);
            set((state) => {
                state.isEditing = false;
                state.saved = null;
            });
        },

        cancelEdit: () => {
            set((state) => {
                state.isEditing = false;
                state.activeObject = state.saved;
                state.saved = null;
                state.updateList();
            });
        },

        clear: () => {
            set((state) => {
                state.list = [];
                state.activeObject = null;
                state.saved = null;
            });
        },
    }));

    // ************************************************************************

    async getById(id: string) {
        const dto = await getRequest<DtoType>(`/${this.apiEndpoint}/${id}`);
        return this.fromDto(dto);
    }

    async getList() {
        return getRequest<DbObject[]>(`/${this.apiEndpoint}/list`);
    }

    async save(object: ObjectType) {
        const dto = this.toDto(object);
        let result;
        if (dto.id != null) {
            result = await putRequest<DtoType>(`/${this.apiEndpoint}/`, dto);
        } else {
            result = await postRequest<DtoType>(`/${this.apiEndpoint}/`, dto);
        }
        return this.fromDto(result);
    }

    async delete(id: string) {
        return deleteRequest(`/${this.apiEndpoint}/`, id);
    }

    // ************************************************************************

    private editObject: ObjectType | null = null;

    startEditing(object: ObjectType) {
        this.editObject = object;
        this.setIsEditing(true);
    }

    stopEditing() {
        this.setIsEditing(false);
        this.editObject = null;
    }

    getEditObject() {
        return this.editObject;
    }

    // ************************************************************************

    abstract fromDto(dto: DtoType): ObjectType;
    abstract toDto(object: ObjectType): DtoType;

    setIsEditing(isEditing: boolean) { }

    hookPostSelect() { }
}
