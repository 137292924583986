
import { createStore } from "./zustand";
import { Theme } from "../types/theme";


type State = {
    theme: Theme;
    selectedWaypoint: number|undefined;
    isSidebarVisible: boolean;
    setSelectedWaypoint: (index: number) => void;
    resetSelectedWaypoint: () => void;
    setSidebarVisible: (isVisible: boolean) => void;
};

const useGui = createStore<State>((set, get) => ({
    theme: new Theme(),

    selectedWaypoint: undefined,
    setSelectedWaypoint: (index) => {
        set(state => {
            state.selectedWaypoint = index;
        });
    },
    resetSelectedWaypoint: () => {
        set(state => {
            state.selectedWaypoint = undefined;
        });
    },

    isSidebarVisible: false,
    setSidebarVisible: (isVisible) => {
        set((state) => {
            state.isSidebarVisible = isVisible;
        });
    }
}));

export default useGui;
