
import styled from "styled-components";

// ****************************************************************************

export const IconTextBar = styled.div`
    margin: 0 0 1em 0;
    display: flex;
    justify-content: space-evenly;
`;

const Pair = styled.div`
    text-align: center;
`;

const Icon = styled.div`
    font-size: 26px;
`;

// ****************************************************************************

type PairProps = {
    icon: string;
    text: string;
    tooltip?: string;
}

export function IconTextPair(props: PairProps) {
    const { icon, text, tooltip } = props;
    return (
        <Pair title={tooltip}>
            <Icon>{icon}</Icon>
            <div>{text}</div>
        </Pair>
    );
}
