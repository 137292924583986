
interface MapService {
    name: string;
    url: string;
    attribution: string;
}

export const MapServiceDefault = 'openstreet';

export const MapServiceList: Record<string, MapService> =
{
    openstreet: {
        name: "OpenStreet-Map",
        url: "https://{s}.maptiles.ieem-ka.de/openstreetmap/{z}/{x}/{y}.png",
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    },

    // komoot: {
    //   name: "Komoot-Map",
    //   url: "https://ebike-planner.ieem-ka.de/maptiles/komoot/{z}/{x}/{y}.png",
    //   attribution: '&copy; <a href="http://osm.org/copyright">Maplibre and Komoot and OpenStreetMap</a> contributors',
    // },

    mapboxstreets: {
      name: "Mapbox-Streets-Map",
      url: "https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFudWVsZ2FzcyIsImEiOiJja3VpYmJ4NmIwODV5MnZwZTNiNXVhdmFpIn0.zNbtQue2u55uIxVyUfgxvw",
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    },

    mapboxsatellite: {
      name: "Mapbox-Satellite-Map",
      url: "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFudWVsZ2FzcyIsImEiOiJja3VpYmJ4NmIwODV5MnZwZTNiNXVhdmFpIn0.zNbtQue2u55uIxVyUfgxvw",
      attribution: '© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>',
    },
};
