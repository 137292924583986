
import useUserStore from "../../stores/userstore";

import NarrowContent from "../../Layout/NarrowContent";
import { DescriptionList, DescriptionTag, DescriptionValue } from "../GuiElements/DescriptionList";
import UserAccountDeleteForm from "./UserAccountDeleteForm";
import UserPasswordChangeForm from "./UserPasswordChangeForm";
import LayoutColumns from "../../Layout/LayoutColumns";


export default function UserProfilePage() {

    const user = useUserStore((state) => state.data);
    if (!user) return null;

    const name = user.fullName? user.fullName: user.userName;

    return (
        <NarrowContent>
            <h1>{name}</h1>
            <DescriptionList>
                <DescriptionTag>Benutzername</DescriptionTag>
                <DescriptionValue>{user.userName}</DescriptionValue>
                <DescriptionTag>E-Mail</DescriptionTag>
                <DescriptionValue>{user.email}</DescriptionValue>
            </DescriptionList>

            <LayoutColumns>
                <div><UserPasswordChangeForm/></div>
                <div><UserAccountDeleteForm/></div>
            </LayoutColumns>
        </NarrowContent>
    );
}
