
import { ReactElement } from "react";
import { createStore } from "./zustand";
import { DialogButton, Modal } from "../types/modal";

type State = {
    modal: Modal | null;
    show: (content: ReactElement, buttons: DialogButton[]) => void;
    close: () => void;
};

const gModalStack: Modal[] = [];

const useModalDialog = createStore<State>((set, get) => ({
    modal: null,

    show: (content, buttons) => {
        const modal = get().modal;
        if (modal) gModalStack.push(modal);
        set((state) => {
            state.modal = new Modal(content, buttons);
        });
    },

    close: () => {
        set((state) => {
            if (gModalStack.length > 0) {
                state.modal = gModalStack.pop()!;
            } else {
                state.modal = null;
            }
        });
    },
}));

export default useModalDialog;
