
import { immerable } from "immer";


export class Theme {
    [immerable] = true;

    cols = {
        bg: "#fff",             // page background
        text: "#444",           // main text color
        high1: "#2869af",       // highlight color 1
        textHigh1: "#fff",      // text on highlight 1
        high2: "#288732",       // highlight color 2
        textHigh2: "#fff",      // text on highlight 2
        high3: "#d72305",       // highlight color 3
        textHigh3: "#fff",      // text on highlight 3
    };
}
