
import styled from "styled-components";


const gErrorStrings: { [key: string]: string } = {
    // login-specific errors
    'error:unauthorized':
        'Die Anmeldung ist fehlgeschlagen. Bitte überprüfe Deinen Benutzernamen und Passwort.',
    'error:invalid_login_token':
        'Deine letzte Sitzung konnte leider nicht wiederhergestellt werden. Bitte melde Dich neu an.',
    // reset-password-specific errors
    'error:user_unknown':
        'Der angegebene Benutzer konnte nicht gefunden werden. Bitte überprüfe die genaue Schreibweise.',
    // registration-specific errors
    'error:user_empty':
        'Der Benutzername darf nicht leer sein.',
    'error:email_empty':
        'Die E-Mail-Adresse darf nicht leer sein.',
    "error:password_empty":
        'Das Passwort darf nicht leer sein.',
    'error:password_mismatch':
        'Die beiden Passwörter stimmen nicht überein.',
    'error:user_exists':
        'Der angegebene Benutzername ist bereits vergeben.',
    'error:email_exists':
        'Die angegebene E-Mail-Adresse wurde bereits registriert.',
    // new password specific
    'error:invalid_password_reset_token':
        'Der Link ist leider ungültig.',
    'error:invalid_user':
        'Der Benutzer konnte nicht gefunden werden.',
    // change password specific
    'success:password_changed':
        'Das Passwort wurde erfolgreich geändert.',
    'error:invalid_password':
        'Das Passwort ist nicht richtig.',
    // general errors
    'error:server':
        'Ein Serverfehler ist aufgetreten. Bitte versuche es später erneut.',
    'error:default':
        'Ein unbekannter Fehler ist aufgetreten. Bitte versuche es später erneut.',
}


const ErrorMessage = styled.p`
    color: red;
`;


export function getLoginErrorMessage(error: string) {
    let message;
    if (error in gErrorStrings)
        message = gErrorStrings[error];
    else
        message = gErrorStrings['error:default'];
    return <ErrorMessage>{message}</ErrorMessage>;
}
