
import { createStore } from "../stores/zustand";
import DbCollection from "../db/collection";
import { addLoginStateObserver, LoginState } from "../stores/userstore";
import { Route, RouteDto } from "../types/route";

// ****************************************************************************

class RouteCollection extends DbCollection<Route, RouteDto>
{
    constructor() {
        super("routeplan");
    }

    createNewEmptyRoute() {
        const route = new Route();
        route.name = "Neue Route";
        useRouteStore.getState().set(route);
    }

    fromDto(dto: RouteDto): Route {
        return new Route(dto);
    }

    toDto(route: Route): RouteDto {
        return new RouteDto(route);
    }
}

// ****************************************************************************

export const RouteDb = new RouteCollection();

const useRouteStore = createStore(RouteDb.storeConfig);
// no need to trigger state update, we can just set the variable
useRouteStore.getState().allowEmpty = true;

addLoginStateObserver((newState, oldState) => {
    if (newState === LoginState.LOGGED_IN) {
        RouteDb.createNewEmptyRoute();
        useRouteStore.getState().updateList();
    } else if (newState === LoginState.LOGGED_OUT) {
        useRouteStore.getState().clear();
    }
});

export default useRouteStore;
