
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { EbikeType, EbikeTypeStrings, Ebike, TireType, TireTypeStrings, ShiftboxManufacturer, ShiftboxManufacturerStrings } from "../../types/ebike";
import useEbikeStore, { EbikeDb } from "../../Database/EbikeCollection";
import useMotorStore from "../../Database/MotorCollection";

import { FormGroupRow, NumericInput, SaveAbortButtons, Select, TextInput } from "../GuiElements/forms";
import { ModalDialog } from "../GuiElements/modaldialog";

// ****************************************************************************

function EbikeSettingsForm()
{
    const ebike = EbikeDb.getEditObject();

    const motorList = useMotorStore((state) => state.list);
    const motor = useMotorStore((state) => state.activeObject);

    const { register, watch, setValue, handleSubmit, formState: { errors }, } = useForm<Ebike>({
        mode: "onChange",
        defaultValues: EbikeDb.getEditObject()!,
        shouldUseNativeValidation: true,
    });

    const onSubmit = (ebike: Ebike) => {
        useEbikeStore.getState().saveAndSelect(ebike);
        EbikeDb.stopEditing();
    };

    const onAbort = () => {
        EbikeDb.stopEditing();
    };

    const getShiftboxManufacturers = useMemo(() =>
        (gearCount?: number) => {
            const forceShimano = (gearCount && gearCount < 11);
            if (forceShimano) {
                if (ebike && ebike.shiftbox.manufacturer !== ShiftboxManufacturer.Shimano)
                    setValue('shiftbox.manufacturer', ShiftboxManufacturer.Shimano);
                return [ShiftboxManufacturer.Shimano];
            } else {
                return Object.values(ShiftboxManufacturer);
            }
        },
        [setValue, ebike]
    );

    const [ shiftboxManufacturers, setShiftboxManufacturers ] =
        useState(getShiftboxManufacturers(ebike?.shiftbox.gearCount));

    useEffect(() => {
        const watchSubsrc = watch((value, { name }) => {
            if (value && value.shiftbox && value.shiftbox.gearCount) {
                if (name === 'shiftbox.gearCount')
                    setShiftboxManufacturers(getShiftboxManufacturers(value.shiftbox.gearCount));
            }
        });
        return () => watchSubsrc.unsubscribe();
    }, [watch, getShiftboxManufacturers]);

    if (!ebike) return null;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextInput error={errors.name?.message}
                label="Name"
                {...register("name", { required: "Bitte gib einen Namen an." })}
            />
            <Select error={errors.ebikeType?.message}
                label="E-Bike-Typ"
                options={Object.values(EbikeType).map((t) => ({ key: t, value: EbikeTypeStrings[t] }))}
                {...register("ebikeType", { required: "Bitte wähle einen E-Bike-Typ aus." })}
            />
            <Select error={errors.tireType?.message}
                label="Reifentyp"
                options={Object.values(TireType).map((t) => ({ key: t, value: TireTypeStrings[t] }))}
                {...register("tireType", { required: "Bitte wähle einen Reifentyp aus." })}
            />
            <NumericInput error={errors.tireSizeIn?.message}
                label="Reifengröße" unit="Zoll" step={0.5}
                {...register("tireSizeIn", {
                    required: "Bitte gib eine Reifengröße an.",
                    min: { value: 10, message: "Die Reifengröße muss größer als 10 Zoll sein." },
                    max: { value: 30, message: "Die Reifengröße muss kleiner als 30 Zoll sein." },
                    valueAsNumber: true,
                })}
            />
            <NumericInput error={errors.batteryCapacityWh?.message}
                label="Maximale Akku-Kapazität" unit="Wh"
                {...register("batteryCapacityWh", {
                    required: "Bitte gib den Energieinhalt an.",
                    min: { value: 100, message: "Der Energieinhalt muss größer als 100 Wh sein." },
                    max: { value: 2000, message: "Der Energieinhalt muss kleiner als 2000 Wh sein." },
                    valueAsNumber: true,
                })}
            />
            { (motorList && motor) &&
                <Select error={errors.motor?.message}
                    label="Antrieb"
                    options={motorList.map((v) => ({ key: v.id!, value: v.name }))}
                    {...register("motor", { required: "Bitte wähle einen Antrieb aus." })}
                />
            }

            <FormGroupRow label="Schaltung">
                <Select error={errors.shiftbox?.gearCount?.message}
                    label="Anzahl Gänge"
                    options={[9, 10, 11, 12].map((v) => ({ key: v, value: v }))}
                    {...register("shiftbox.gearCount", { required: "Bitte wähle die Anzahl der Gänge aus." })}
                />
                <Select error={errors.shiftbox?.manufacturer?.message}
                    label="Hersteller"
                    options={shiftboxManufacturers.map((m) => ({ key: m, value: ShiftboxManufacturerStrings[m] }))}
                    {...register("shiftbox.manufacturer", { required: "Bitte wähle den Hersteller aus." })}
                />
            </FormGroupRow>

            <SaveAbortButtons onAbort={onAbort} />
        </form>
    );
};

// ****************************************************************************

export default function EbikeSettingsDialog()
{
    const isEditing = useEbikeStore((state) => state.isEditing);
    if (!isEditing) return null;
    return (
        <ModalDialog title="E-Bike Einstellungen">
            <EbikeSettingsForm/>
        </ModalDialog>
    );
}
