
import React, { MouseEventHandler } from "react";
import styled from "styled-components";

// ****************************************************************************

const LinkButton = styled.button`
    padding: 0!important;
    background: none!important;
    border: none;
    cursor: pointer;
    color: #2869af;
    text-decoration: none;
    &:hover { text-decoration: underline }
`;

type LinkProps = React.PropsWithChildren<{
    onClick?: MouseEventHandler;
}>;

export function Link(props: LinkProps)
{
    const { children, onClick } = props;

    if (onClick)
        return <LinkButton onClick={onClick}>{children}</LinkButton>;
    else
        return <LinkButton>{children}</LinkButton>;
}

// ****************************************************************************

const MenuHolder = styled.ul`
    margin: .2em 0;
    padding: 0;
    list-style-type: none;

    li {
        display: inline-block;
        margin: 0 1em 0 0;
        padding: 0;
    }
`;

type MenuProps = React.PropsWithChildren<{}>

export function Menu(props: MenuProps) {
    const { children } = props;

    return (
        <nav>
            <MenuHolder>{
                React.Children.map(children, child => {
                    return (child != null)? (<li>{child}</li>): null;
                })
            }</MenuHolder>
        </nav>
    );
}
