
import React, { useState } from "react";
import styled from "styled-components";

import { Button, ButtonBox } from "./modaldialog";
import { ButtonShowHide } from "./IconButtons";

// ****************************************************************************

interface FormFieldProps {
    name?: string;
    label?: string;
    disabled?: boolean;
    error?: string;
    onBlur?: React.FocusEventHandler;
}

interface FormInputProps extends FormFieldProps {
    onChange: React.ChangeEventHandler<HTMLInputElement>;
}

interface FormBoolInputProps extends FormInputProps {
    value?: boolean;
}

interface FormTextInputProps extends FormInputProps {
    value?: string;
}

interface FormNumericProps extends FormInputProps {
    value?: number;
    unit?: string;
    min?: string|number;
    max?: string|number;
    step?: number;
}

interface FormSelectProps extends FormFieldProps {
    value?: string|number;
    defaultValue?: string|number;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    options: { key: string|number; value: string|number; }[];
}

// ****************************************************************************

const FormGroupRowBox = styled.fieldset`
    width: 100%;
    margin: .5em 0;
    padding: 0 .5em .5em;
    border-radius: 5px;
    display: flex;
    flex-flow: row nowrap;
    gap: .4em;
    justify-content: space-between;
    align-items: center;
    legend { font-size: 82%; padding: 0 .3em; }
    fieldset { flex: 1 1 0; margin: 0; }
`;

const FormFieldStyle = styled.fieldset`
    margin: .7em 0;
    padding: 0 .5em .3em;
    display: block;
    border: 1px solid #888;
    border-radius: 5px;
    legend { font-size: 82%; padding: 0 .3em; }
`;

const FormFieldLabelStyle = styled.label`
    margin: .7em 0;
    padding: 0;
    display: flex;
    gap: .5em;
    align-items: flex-start;
    input { margin: 0; }
    div { flex-grow: 1; }
`;

const FormFlexInputBox = styled.div`
    width: 100%;
    display: flex;
    gap: .4em;
`;

const FormTextInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    background: none;
`;

const FormNumericInput = styled.input`
    width: 100%;
    flex-grow: 1;
    margin-right: .8em;
    border: none;
    outline: none;
    background-color: transparent;
`;

const FormCheckbox = styled.input`
    display: inline;
    border: none;
    outline: none;
    background-color: red;
`;

const FormSelect = styled.select`
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
`;

const ErrorMessage = styled.p`
    color: red;
    margin: .2em 0;
`;

// ****************************************************************************

const FormField = (props: React.PropsWithChildren<{ label?: string, error?: string }>) => (
    <FormFieldStyle>
        {props.label && <legend>{props.label}</legend>}
        {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
        {props.children}
    </FormFieldStyle>
);

const FormFieldLabel = (props: React.PropsWithChildren<{ label?: string, error?: string }>) => (
    <FormFieldLabelStyle>
        {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
        {props.children}
        {props.label && <div>{props.label}</div>}
    </FormFieldLabelStyle>
);

// ****************************************************************************

export const FormGroupRow = (props: React.PropsWithChildren<{ label: string, error?: string }>) => (
    <FormGroupRowBox>
        <legend>{props.label}</legend>
        { props.error && <ErrorMessage>{props.error}</ErrorMessage> }
        {props.children}
    </FormGroupRowBox>
);

// ****************************************************************************

export const TextInput = React.forwardRef<HTMLInputElement, FormTextInputProps>((props, ref) => {
    const { value, name, disabled, onChange, onBlur } = props;
    return (
        <FormField label={props.label} error={props.error}>
            <FormTextInput value={value} disabled={disabled} name={name} ref={ref} onChange={onChange} onBlur={onBlur} />
        </FormField>
    );
});

export const PasswordInput = React.forwardRef<HTMLInputElement, FormInputProps>((props, ref) => {
    const { name, disabled, onChange, onBlur } = props;
    const [ showpasswd, setShowpasswd ] = useState(false);
    return (
        <FormField label={props.label} error={props.error}>
            <FormFlexInputBox>
                <FormTextInput type={showpasswd? "text": "password"} disabled={disabled} name={name} ref={ref} onChange={onChange} onBlur={onBlur} />
                <ButtonShowHide valid={!showpasswd} onClick={() => setShowpasswd(!showpasswd)}/>
            </FormFlexInputBox>
        </FormField>
    );
});

export const NumericInput = React.forwardRef<HTMLInputElement, FormNumericProps>((props, ref) => (
    <FormField label={props.label} error={props.error}>
        { props.unit &&
            <FormFlexInputBox>
                <FormNumericInput type="number" min={props.min} max={props.max} step={props.step}
                    name={props.name} value={props.value} disabled={props.disabled} onChange={props.onChange} onBlur={props.onBlur} ref={ref} />
                {props.unit}
            </FormFlexInputBox>
        }
        { !props.unit &&
            <FormNumericInput type="number" min={props.min} max={props.max} step={props.step}
                name={props.name} value={props.value} disabled={props.disabled} onChange={props.onChange} onBlur={props.onBlur} ref={ref} />
        }
    </FormField>
));

export const Checkbox = React.forwardRef<HTMLInputElement, FormBoolInputProps>((props, ref) => (
    <FormFieldLabel label={props.label} error={props.error}>
        <FormCheckbox type="checkbox" disabled={props.disabled} checked={props.value}
            name={props.name} ref={ref} onChange={props.onChange} onBlur={props.onBlur}
        />
    </FormFieldLabel>
));

export const Select = React.forwardRef<HTMLSelectElement, FormSelectProps>((props, ref) => (
    <FormField label={props.label} error={props.error}>
        <FormSelect disabled={props.disabled} name={props.name} ref={ref} onChange={props.onChange} onBlur={props.onBlur}
            defaultValue={props.defaultValue} value={props.value}
        >
            {props.options.map(o => <option value={o.key} key={o.key} >{o.value}</option>)}
        </FormSelect>
    </FormField>
));

// ****************************************************************************

interface FormSaveAbortProps {
    onSave?: () => void;
    onAbort: () => void;
}

export const SaveAbortButtons = (props: FormSaveAbortProps) => {

    const onSave = (ev: React.MouseEvent) => {
        if (!props.onSave) return;
        ev.preventDefault();
        ev.stopPropagation();
        props.onSave();
    };

    const onAbort = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onAbort();
    };

    const buttonType = props.onSave? undefined: "submit";
    const buttonOnClick = props.onSave? onSave: undefined;

    return (
        <ButtonBox>
            <Button type={buttonType} onClick={buttonOnClick}>Speichern</Button>
            <Button onClick={onAbort}>Abbrechen</Button>
        </ButtonBox>
    );
};

export const ActionButton = (props: { label: string, onClick: () => void }) => {
    const onClick = (ev: React.MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        props.onClick();
    };
    return <Button onClick={onClick}>{props.label}</Button>;
}

export const SubmitButton = (props: { label?: string }) => (
    <div className="button">
        <button type="submit">{props.label || 'Absenden'}</button>
    </div>
);
