
import { ApiEndpoint } from "../config";
import { getSessionToken } from "../stores/Tokens";


class HttpError extends Error {
    public status: number;
    public body: any;

    constructor(status: number, body: any, ...params: any[]) {
        super(...params);
        this.name = 'HttpError';
        this.status = status;
        this.body = body;
    }
}


export async function getRequest<T>(url: string): Promise<T> {
    const response = await fetch(ApiEndpoint + url, {
        method: "GET",
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new HttpError(response.status, await response.json());
    }
    return await (response.json() as Promise<T>);
}

export async function postRequest<T>(url: string, data: T): Promise<T> {
    const response = await fetch(ApiEndpoint + url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new HttpError(response.status, await response.json());
    }
    return await (response.json() as Promise<T>);
}

export async function postRequest2<T,Z>(url: string, data: T): Promise<Z> {
    const response = await fetch(ApiEndpoint + url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new HttpError(response.status, await response.json());
    }
    return await (response.json() as Promise<Z>);
}

export async function putRequest<T>(url: string, data: T): Promise<T> {
    const response = await fetch(ApiEndpoint + url, {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(data),
    });
    if (!response.ok) {
        throw new HttpError(response.status, await response.json());
    }
    return await (response.json() as Promise<T>);
}

export async function deleteRequest(url: string, id: string) {
    const response = await fetch(ApiEndpoint + url + id, {
        method: "DELETE",
        headers: getHeaders(),
    });
    if (!response.ok) {
        throw new HttpError(response.status, '');
    }
}


function getHeaders() {
    const headers: {[k: string]: any} = { "Content-Type": "application/json" };
    const sessionToken = getSessionToken();
    if (sessionToken != null)
        headers.Authorization = "Bearer " + sessionToken;
    return headers;
}
