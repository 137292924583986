
import styled from "styled-components";

import useRouteStore from "../../db/routedb";
import useModalDialog from "../../stores/modalstore";
import { CloseButton } from "../GuiElements/modaldialog";
import { Route } from "../../types/route";
import RouteOverview from "./RouteOverview";
import useUserStore from "../../stores/userstore";

// ****************************************************************************

const RouteList = styled.div`
    margin: 0;
    padding: 0;

    h1 {
        margin: 0 0 .6em;
        font-size: 1.2em;
        border-bottom: 1px solid #000;
    }

    .route-overview {
        margin: 1em 0;
        padding: 5px 8px;
        border: 1px solid #000;
        cursor: pointer;
        display: flex;
        gap: .6em;

        h2 {
            flex-grow: 1;
            margin: 0;
            padding: 0;
            font-size: 1em;
        }
    }
`;

// ****************************************************************************

export default function RouteSelection() {

    const routeList = useRouteStore((state) => state.list) as Route[];
    const userId = useUserStore(state => state.data!.id);
    const privateList = routeList.filter(route => !route.isPublic || (route.ownerId === userId));
    const publicList = routeList.filter(route => route.isPublic && (route.ownerId !== userId));

    return (
        <>
            <RouteList>
                <h1>Meine Routen</h1>
                { privateList.map((route) => <RouteOverview route={route} community={false} key={route.id} />) }
            </RouteList>

            <RouteList>
                <h1>Community</h1>
                { publicList.map((route) => <RouteOverview route={route} community={true} key={route.id} />) }
            </RouteList>
        </>
    );
}

// ****************************************************************************

export function showRouteSelection()
{
    useModalDialog.getState().show(<RouteSelection/>, [ CloseButton ]);
}
