
import { Route } from "../../types/route";

import useModalDialog from "../../stores/modalstore";
import { DialogButton } from "../../types/modal";

// ****************************************************************************

export async function showDialogRouteCopy()
{
    return new Promise<boolean>((resolve, reject) => {
        useModalDialog.getState().show(
            <div>
                <p>
                    Die aktuelle Route wurde von einem anderen Benutzer erstellt und mit der Community geteilt.
                    Du kannst diese Route nicht direkt verändern.
                </p>
                <p>
                    Wenn Du unten "Speichern" auswählst, wird eine Kopie in Deinen eigenen Routen angelegt.
                </p>
            </div>,
            [
                new DialogButton('Speichern', () => Route.copyActiveRoute().then(() => resolve(true)), 2),
                new DialogButton('Abbrechen', () => resolve(false)),
            ]
        );
    })
}

// ****************************************************************************

export async function showDialogRouteShare(routeId: string, routeName: string)
{
    return new Promise<void>((resolve, reject) => {
        useModalDialog.getState().show(
            <div>
                <p>
                    Möchtest Du die Route <b>{routeName}</b> mit der Community teilen?<br/>
                    Andere Benutzer können die Route dann öffnen, aber nicht ändern.
                </p>
            </div>,
            [
                new DialogButton('Teilen', () => Route.shareRoute(routeId).then(() => resolve()), 2),
                new DialogButton('Abbrechen', () => resolve()),
            ]
        );
    });
}

// ****************************************************************************

export async function showDialogRouteUnShare(routeId: string, routeName: string)
{
    return new Promise<void>((resolve, reject) => {
        useModalDialog.getState().show(
            <div>
                <p>
                    Möchtest Du die Route <b>{routeName}</b> nicht mehr mit der Community teilen?
                </p>
            </div>,
            [
                new DialogButton('Nicht mehr Teilen', () => Route.unshareRoute(routeId).then(() => resolve()), 2),
                new DialogButton('Abbrechen', () => resolve()),
            ]
        );
    });
}
