
import { Rider, RiderFitnessStrings } from "../../types/rider";

import { GridContainer, GridPair } from "../../GridComponents";

import useRiderStore, { RiderDb } from "../../Database/RiderCollection";

import { AccordionPanel } from "../GuiElements/accordion";
import { Link, Menu } from "../GuiElements/Menu";
import { Select } from "../GuiElements/forms";
import { showDialogConfirmDelete } from "../dialogs/edit-dialogs";

// ****************************************************************************

function deleteRider(ev: React.MouseEvent, name: string)
{
    ev.preventDefault();
    ev.stopPropagation();
    showDialogConfirmDelete(
        <span>den Fahrer <b>{name}</b></span>,
        () => useRiderStore.getState().delete(),
    );
}

// ****************************************************************************

export default function RiderControl() {
    const riderList = useRiderStore((state) => state.list);
    const rider = useRiderStore((state) => state.activeObject);

    let label = "Fahrer";
    if (rider) {
        label += ` (${rider.name})`;
    }

    let weightKg, heightCm, fitness;
    if (rider) {
        weightKg = rider.weightKg;
        heightCm = rider.heightCm;
        fitness = Rider.getFitnessRating(rider);
    } else {
        const r = Rider.getNew();
        weightKg = r.weightKg;
        heightCm = r.heightCm;
        fitness = Rider.getFitnessRating(r)
    }

    let riderSelect;
    if (riderList && rider) {
        riderSelect = (
            <Select
                options={riderList.map((value) => ({key: value.id!, value: value.name}))}
                onChange={(ev) => useRiderStore.getState().setById(ev.target.value)}
            />
        );
    } else {
        riderSelect = "Standard Fahrer";
    }

    return (
        <AccordionPanel heading={label}>
            <div>
                {riderSelect}
                <Menu>
                    { rider && <Link onClick={() => RiderDb.startEditing(new Rider(rider))}>Ändern</Link> }
                    { rider && <Link onClick={(ev) => deleteRider(ev, rider.name)}>Löschen</Link> }
                    <Link onClick={() => RiderDb.startEditing(Rider.getNew())}>Neu</Link>
                </Menu>
            </div>

            <GridContainer>
                <GridPair left="Gewicht" right={`${weightKg} kg`} />
                <GridPair left="Größe" right={`${heightCm} cm`} />
                <GridPair left="Fitness" right={RiderFitnessStrings[fitness]} />
            </GridContainer>
        </AccordionPanel>
    );
};
