
import styled from "styled-components";

import { DesktopMenu, MobileMenu } from "../Components/Navigation/MainMenu";
import useBrowserInfo from "./BrowserInfo";

import { ReactComponent as HkaHorizontalSvg } from "./Images/hka_h.svg";
import { ReactComponent as IeemHorizontalSvg } from "./Images/ieem_h.svg";
import { ReactComponent as HkaIeemHorizontalSvg } from "./Images/hka_ieem_h.svg";


// ****************************************************************************

const HeaderContainer = styled.header`
    width: 100%;
    margin: 1em 0;
`;

const HeaderTop = styled.div`
    margin: 0 1em;
    display: flex;
    justify-content: space-between;
`;

// ****************************************************************************

const IeemHorizontal = styled(IeemHorizontalSvg)`
    height: 50px;
    width: auto;
    margin-right: 40px;
`;

const HkaHorizontal = styled(HkaHorizontalSvg)`
    height: 50px;
    width: auto;
    margin-left: 40px;
`;

const HkaIeemHorizontal = styled(HkaIeemHorizontalSvg)`
    max-width: 100%;
    height: auto;
`;

// ****************************************************************************

function HeaderLogo()
{
    const browser = useBrowserInfo();

    if (browser.width >= 480)
        return (
            <>
                <IeemHorizontal/>
                { !browser.isMobile && <DesktopMenu/>}
                <HkaHorizontal/>
            </>
        );
    else
        return (<HkaIeemHorizontal/>);
}

// ****************************************************************************

export default function Header() {
    return (
        <HeaderContainer>
            <HeaderTop>
                <HeaderLogo/>
            </HeaderTop>
            <MobileMenu/>
        </HeaderContainer>
    );
}
