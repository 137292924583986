
import { useState } from "react";
import styled from "styled-components";
import { useMap } from "react-leaflet";

import useMapStore from "../../stores/mapstore";
import useRouteStore from "../../db/routedb";
import { IconMapCenter, IconMapLayers } from "../GuiElements/IconButtons";
import { MapServiceList } from "../../types/mapservice";

// ****************************************************************************

const MapButton = styled.button<{$showpopup?: boolean}>`
    position: relative;
    background: #fff;
    border-radius: 50%;
    margin: 0;
    padding: 8px;
    cursor: pointer;
    pointer-events: auto;
    border: none;
    svg { width: 30px; height: 30px; }
    .popup {
        display: ${p => p.$showpopup? 'block': 'none'};
        position: absolute;
        right: 60px; top: 0;
        width: 250px;
        border: solid 2px #000;
        border-radius: 5px;
        padding: 5px;
        color: ${p => p.theme.cols.textHigh2};
        background-color: ${p => p.theme.cols.high2};
        z-index: 1000;
        cursor: auto;
        p { cursor: pointer; padding: 3px 0; margin: 0; }
        p:hover { background-color: ${p => p.theme.cols.high1}; }
    }
`;

// ****************************************************************************

const onCenter = (map: L.Map) => {
    const route = useRouteStore.getState().activeObject;
    if (!route || (route.waypoints.length < 2)) return;
    map.fitBounds(route.getRouteBBox());
};

const onSelectLayer = (key: string) => {
    useMapStore.getState().setMapService(key);
}

// ****************************************************************************

export function MapBtnCenter() {
    const map = useMap();
    return (
        <MapButton onClick={() => onCenter(map)} title="Route zentrieren"><IconMapCenter/></MapButton>
    );
}

export function MapBtnLayers() {
    const [ showPopup, setShowPopup ] = useState(false);
    return (
        <div>
            <MapButton onClick={() => setShowPopup(!showPopup)} title="Kartenauswahl" $showpopup={showPopup}>
                <IconMapLayers/>
                <div className="popup">
                    { Object.entries(MapServiceList).map(([key, value]) =>
                        <p key={key} onClick={() => onSelectLayer(key)}>{value.name}</p>)
                    }
                </div>
            </MapButton>
        </div>
    );
}
